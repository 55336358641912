import { api } from '../api';

export default async function MyProfile() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  const { data } = await api.get(`/profile/info`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function EditProfile(
  fullName: string,
  lastName: string,
  email: string,
  phone: string
) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  await api.put(
    `/customer/update`,
    {
      fullName,
      lastName,
      email,
      phone,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}
