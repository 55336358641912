import { Pagination } from 'antd';

import './pagination-elem.scss';

interface IPaginationElemProps {
  total: number;
  currentPage: number;
  onChange: (page: number) => void;
}

function PaginationElem({
  currentPage,
  total,
  onChange,
}: IPaginationElemProps) {
  return (
    <div className="pagination-elem">
      <Pagination
        className="pagination-elem__custom"
        showSizeChanger={false}
        current={currentPage}
        total={total}
        onChange={onChange}
        showLessItems
        pageSize={1}
      />
    </div>
  );
}

export default PaginationElem;
