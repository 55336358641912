import React, { useCallback, useContext, useEffect } from 'react';
import './style.css';
import { Button, Form, Input, Typography, theme, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import useCreateUser from '../../../api/auth/auth-hooks';
import { UserInfoContext } from '../index';
import useCities from '../../../api/common/common-hooks';
import { useAuth } from '../../../hooks/authContext';

type PasswordFormProps = {
  setIsNext: React.Dispatch<React.SetStateAction<string>>;
  onCloseModal: () => void;
};
message.config({
  top: 100,
});
export default function CityForm({
  setIsNext,
  onCloseModal,
}: PasswordFormProps) {
  const { token } = theme.useToken();
  const { data: CityData } = useCities();
  const context = useContext(UserInfoContext);
  const [form] = Form.useForm();
  const { mutate, isSuccess } = useCreateUser();
  const navigate = useNavigate();
  const { login } = useAuth();
  const onNextStep = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        const { firstName, lastName, city } = values;
        context.setUser({
          ...context.user,
          cityId: city,
          fullName: `${firstName} ${lastName}`,
        });

        mutate(
          {
            cityId: city,
            lastName: `${lastName}`,
            password: context.user.password,
            phoneNumber: context.user.phoneNumber,
            smsCode: '',
            fullName: `${firstName}`,
          },
          {
            onSuccess: (data) => {
              login(data.token);
              message.success('Успешный вход!');
              navigate('/my-profile');
              onCloseModal();
            },
            onError: () => {
              message.error(`Ошибка входа попробуйте позже`);
              onCloseModal();
            },
          }
        );
      })
      .catch((errorInfo) => {
        message.error('Ошибка валидации:', errorInfo);
      });
  }, [form, context, mutate, setIsNext]);

  const onBack = () => {
    setIsNext('initial');
  };
  useEffect(() => {
    if (isSuccess) {
      navigate('/my-profile');
    }
  }, [isSuccess, navigate]);
  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={4} style={{ width: '100%' }}>
          Введите имя, фамилию и выберите город
        </Typography.Title>
      </div>
      <Form form={form} layout="vertical" style={{ width: '100%' }}>
        <Form.Item
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите вашу фамилию',
            },
          ]}
        >
          <Input
            placeholder="Введите вашу фамилию"
            className="register-form__input"
          />
        </Form.Item>
        <Form.Item
          name="firstName"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите ваше имя',
            },
          ]}
        >
          <Input
            placeholder="Введите ваше имя"
            className="register-form__input"
          />
        </Form.Item>
        <Form.Item
          name="city"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, выберите город',
            },
          ]}
        >
          <select
            className="city-select"
            size={5}
            onChange={(e) => form.setFieldsValue({ city: e.target.value })}
            value={form.getFieldValue('city') || ''}
          >
            <option value="" disabled>
              Город
            </option>
            {CityData && CityData.length > 0 ? (
              CityData.map((item: { id: number; name: string }) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))
            ) : (
              <option disabled>Нет городов</option>
            )}
          </select>
        </Form.Item>

        <Button
          onClick={onNextStep}
          className="register-form__submit-btn"
          style={{
            backgroundColor: token.colorPrimaryBg,
            border: token.colorPrimaryBg,
            width: '100%',
            marginBottom: '8px',
          }}
        >
          Продолжить
        </Button>

        <Button
          onClick={onBack}
          className="register-form__back-btn"
          style={{
            borderColor: token.colorPrimaryBg,
            color: token.colorPrimaryBg,
            width: '100%',
          }}
        >
          Назад
        </Button>
      </Form>
    </div>
  );
}
