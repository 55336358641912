// // src/components/PopularProductCard.jsx
// import { Button, Card, Typography, theme, Image } from 'antd';
// import { HeartFilled, HeartOutlined } from '@ant-design/icons';
// import { motion } from 'framer-motion';
// import './styles.scss';
// import { QueryObserverResult, useMutation } from 'react-query';
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { IphoneImg } from '../../icons';
// import addBasket from '../../api/basket/add-basket';
// import addToFavorite, { deleteFromFavorite } from '../../api/favorite/favorite';
// import { PopularProduct } from '../../types/popular-products';
// import AuthModal from '../UI/Modal';
// import { Role } from '../../types/role';
// import getUserRole from '../../hooks/auth';
//
// interface PopularProductCardProps {
//   product: PopularProduct;
//   refetch: () => Promise<
//     QueryObserverResult<{ content: PopularProduct[] }, Error>
//   >;
// }
//
// export default function PopularProductCard({
//   refetch,
//   product,
// }: PopularProductCardProps) {
//   const [role, setRole] = useState<Role | null>(null);
//   const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
//   const navigate = useNavigate();
//   const { token } = theme.useToken();
//   useEffect(() => {
//     const userRole = getUserRole();
//     setRole(userRole);
//   }, []);
//
//   const mutationBasket = useMutation((id: number) => addBasket(id), {
//     onSuccess: () => {
//       refetch();
//     },
//   });
//
//   const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
//     onSuccess: () => {
//       refetch();
//     },
//   });
//
//   const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
//     onSuccess: () => {
//       refetch();
//     },
//   });
//
//   const addToBasketHandler = (id: number) => {
//     if (role?.role === 'ROLE_CLIENT') {
//       mutationBasket.mutateAsync(id);
//     } else {
//       setIsModalVisible(true);
//     }
//   };
//
//   const handleUpdate = (id: number) => {
//     if (role?.role === 'ROLE_CLIENT') {
//       mutationAddToFavorite.mutateAsync(id);
//     } else {
//       setIsModalVisible(true);
//     }
//   };
//
//   const handleDelete = (id: number) => {
//     deleteFromFavo.mutateAsync(id);
//   };
//
//   const handleCloseModal = () => {
//     setIsModalVisible(false);
//   };
//
//   return (
//     <>
//       <AuthModal visible={isModalVisible} onClose={handleCloseModal} />
//       <motion.div key={product.id} className="popular-product__card">
//         <Card
//           onClick={() =>
//             navigate(`/category/subcategory/product/${product.id}`)
//           }
//           cover={
//             <>
//               <Image
//                 className="popular-product__img"
//                 src={product.photoUrl[0] || IphoneImg}
//                 alt={product.name}
//                 width="initial"
//                 preview={false}
//               />
//               {product.favourites === true ? (
//                 <Button
//                   className="popular-product__favorite-btn"
//                   style={{ color: 'red' }}
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     handleDelete(product.id);
//                   }}
//                 >
//                   <HeartFilled />
//                 </Button>
//               ) : (
//                 <Button
//                   className="popular-product__favorite-btn"
//                   style={{ color: '#201F1F' }}
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     handleUpdate(product.id);
//                   }}
//                 >
//                   <HeartOutlined />
//                 </Button>
//               )}
//             </>
//           }
//         />
//         <div className="popular-product__content">
//           <p className="popular-product__title">
//             {product.name?.length > 15
//               ? `${product.name.slice(0, 15)}...`
//               : (product.name ?? 'Нет названия')}
//           </p>
//           <p className="popular-product__subcategory">
//             {product.subcategoryName || 'Смартфоны'}
//           </p>
//           <p className="popular-product__price">{product.price}₸</p>
//         </div>
//         {product.inBasket === true ? (
//           <p className="popular-product__btn cart-btn-in">В корзине</p>
//         ) : (
//           <Button
//             className="popular-product__btn"
//             style={{
//               backgroundColor: token.colorPrimaryBg,
//             }}
//             onClick={(e) => {
//               e.stopPropagation();
//               addToBasketHandler(product.id);
//             }}
//           >
//             В корзину
//           </Button>
//         )}
//       </motion.div>
//     </>
//   );
// }
// // =======
// //       {popularProducts.map((product) => {
// //         return (
// //           <motion.div key={product.id} className="popular-product__card">
// //             <Card
// //               onClick={() =>
// //                 navigate(`/category/subcaterogy/product/${product.id}`)
// //               }
// //               cover={
// //                 <>
// //                   <Image
// //                     className="popular-product__img"
// //                     src={product.photoUrl[0] || IphoneImg}
// //                     alt={product.name}
// //                     width="initial"
// //                     preview={false}
// //                   />
// //                   {product.favourites === true ? (
// //                     <Button
// //                       className="popular-product__favorite-btn"
// //                       style={{ color: 'red', fontSize: '30px' }}
// //                       onClick={(e) => {
// //                         e.stopPropagation();
// //                         handleDelete(product.id);
// //                       }}
// //                     >
// //                       <HeartFilled />
// //                     </Button>
// //                   ) : (
// //                     <Button
// //                       className="popular-product__favorite-btn"
// //                       style={{ color: 'blue', fontSize: '30px' }}
// //                       onClick={(e) => {
// //                         e.stopPropagation();
// //                         handleUpdate(product.id);
// //                       }}
// //                     >
// //                       <HeartFilled />
// //                     </Button>
// //                   )}
// //                 </>
// //               }
// //             />
// //             <div className="popular-product__content">
// //               <Typography.Title level={4} className="popular-product__title">
// //                 {product.name}
// //               </Typography.Title>
// //               <Typography.Title
// //                 level={4}
// //                 className="popular-product__subcategory"
// //               >
// //                 {product.subcategoryName || 'Смартфоны'}
// //               </Typography.Title>
// //               <Typography.Title level={3} className="popular-product__price">
// //                 {product.price}₸
// //               </Typography.Title>
// //             </div>
// //             {product.inBasket === true ? (
// //               <p className="cart-btn-in">В корзине</p>
// //             ) : (
// //               <Button
// //                 className="popular-product__btn"
// //                 style={{
// //                   backgroundColor: token.colorPrimaryBg,
// //                   display: addedToFavorites ? 'none' : 'block',
// //                 }}
// //                 onClick={(e) => {
// //                   e.stopPropagation();
// //                   addToBasket(product.id);
// //                   setAddedToFavorites(true);
// //                 }}
// //               >
// //                 В корзину
// //               </Button>
// //             )}
// //           </motion.div>
// //         );
// //       })}
// // >>>>>>> dauren-branch
import { Button, Card, Image, theme } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import './styles.scss';
import { QueryObserverResult, useMutation } from 'react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import addBasket from '../../api/basket/add-basket';
import addToFavorite, { deleteFromFavorite } from '../../api/favorite/favorite';
import { PopularProduct } from '../../types/popular-products';
import AuthModal from '../UI/Modal';
import { Role } from '../../types/role';
import getUserRole from '../../hooks/auth';
import { useAuth } from '../../hooks/authContext';

interface PopularProductCardProps {
  product: PopularProduct;
  refetch: () => Promise<
    QueryObserverResult<{ content: PopularProduct[] }, Error>
  >;
}

export default function PopularProductCard({
  refetch,
  product,
}: PopularProductCardProps) {
  const { role } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const mutationBasket = useMutation((id: number) => addBasket(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const addToBasketHandler = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationBasket.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleUpdate = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationAddToFavorite.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleDelete = (id: number) => {
    deleteFromFavo.mutateAsync(id);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <AuthModal visible={isModalVisible} onClose={handleCloseModal} />
      <motion.div key={product.id} className="popular-product__card">
        <Card
          onClick={() =>
            navigate(`/category/subcategory/product/${product.id}`)
          }
          cover={
            <>
              <Image
                className="popular-product__img"
                src={product.photoUrl[0]}
                alt={product.name || 'Изображение продукта'}
                width="initial"
                preview={false}
              />
              {product.favourites ? (
                <Button
                  className="popular-product__favorite-btn"
                  style={{ color: 'red' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(product.id);
                  }}
                >
                  <HeartFilled />
                </Button>
              ) : (
                <Button
                  className="popular-product__favorite-btn"
                  style={{ color: '#201F1F' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdate(product.id);
                  }}
                >
                  <HeartOutlined />
                </Button>
              )}
            </>
          }
        />
        <div className="popular-product__content">
          <p className="popular-product__title">
            {product.name || 'Нет названия'}
          </p>
          <p className="popular-product__subcategory">
            {product.subcategoryName || 'Смартфоны'}
          </p>
          <p className="popular-product__price">{product.pricePerPiece}₸</p>
        </div>
        {product.inBasket ? (
          <p className="popular-product__btn cart-btn-in">В корзине</p>
        ) : (
          <Button
            className="popular-product__btn"
            style={{
              backgroundColor: token.colorPrimaryBg,
            }}
            onClick={(e) => {
              e.stopPropagation();
              addToBasketHandler(product.id);
            }}
          >
            В корзину
          </Button>
        )}
      </motion.div>
    </>
  );
}
