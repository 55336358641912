import { Radio, RadioChangeEvent } from 'antd';
import React, { useEffect } from 'react';
import './style.scss';

export default function CountrySwitch() {
  const storedCountry = localStorage.getItem('country');
  const currentCountry = storedCountry || 'KZ';
  useEffect(() => {
    if (!storedCountry) {
      localStorage.setItem('country', 'KZ');
    }
  }, [storedCountry]);

  const onChangeCountry = ({ target: { value } }: RadioChangeEvent) => {
    localStorage.setItem('country', value);
    document.location.reload();
  };

  return (
    <Radio.Group
      optionType="button"
      buttonStyle="solid"
      className="country-switch"
      defaultValue={currentCountry}
      size="middle"
      onChange={onChangeCountry}
    >
      <Radio.Button
        value="KZ"
        className="main-page__radio-btn"
        style={{ border: 'none', borderRadius: '24px 0 0 24px' }}
      >
        Казахстан
      </Radio.Button>
      <Radio.Button
        value="CHN"
        className="main-page__radio-btn"
        style={{ border: 'none', borderRadius: '0 24px 24px 0' }}
      >
        Китай
      </Radio.Button>
    </Radio.Group>
  );
}
