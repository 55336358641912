import { useQuery } from 'react-query';
import {
  fetchProductDetail,
  categoryProducts,
  getFavouriteProducts,
  getPopularProducts,
  getProductById,
} from './product';
import { getFilials } from '../common/common';

export function useProductDetail() {
  const { data } = useQuery(['productDetail'], fetchProductDetail);

  return { data };
}

export default function useProductCategory(
  categoryId: number | undefined,
  lang: string,
  page: number,
  size: number,
  priceFrom?: number,
  priceTo?: number,
  color?: string,
  name?: string
): {
  isLoading: boolean;
  data: any;
  error: unknown;
  refetch: () => void;
} {
  const { data, isLoading, error, refetch } = useQuery(
    [
      'productCategory12',
      categoryId,
      lang,
      page,
      size,
      priceFrom,
      priceTo,
      color,
      name,
    ],
    () =>
      categoryProducts(
        categoryId,
        lang,
        page,
        size,
        priceFrom,
        priceTo,
        color,
        name
      )
  );
  return {
    isLoading,
    data,
    error,
    refetch,
  };
}

export function usePopularProducts() {
  const { data } = useQuery(['popularProducts'], getPopularProducts);

  return { data };
}

export function useFavouriteProducts() {
  const { data, isLoading, isError, refetch } = useQuery(
    ['favouriteProducts'],
    getFavouriteProducts
  );

  return { data, isLoading, isError, refetch };
}
export function useProductById(id: string | undefined) {
  const { data, refetch } = useQuery(
    ['filials', id],
    () => getProductById(id),
    {
      enabled: !!id,
    }
  );
  return { data, refetch };
}
