import qs from 'qs';
import { api } from '../api';

export default async function getBasketItems() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.get(`/internal/cart/get-all`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
export async function updateAmountBasket(amount: number, id: number) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.put(
    `/internal/cart/update?productId=${id}&amount=${amount}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}
export async function deleteOneItem(id: number) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.delete(
    `/internal/cart/delete-by-id?productId=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}
export async function deleteAllItem() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.delete(`/internal/cart/delete`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
// export async function addOrderBasket(productIds: number[], amount: number[]) {
//   const token = localStorage.getItem('ACCESS_TOKEN');
//   const { data } = await api.post(
//     '/internal/order/create',
//     {},
//     {
//       params: {
//         productIds,
//         amount,
//         filialId: 1,
//       },
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
//
//   return data;
// }
export async function addOrderBasket(productIds: number[], amount: number[]) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.post(
    '/internal/order/create',
    {},
    {
      params: {
        productIds,
        amount,
        filialId: 1,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    }
  );

  return data;
}
