import { useQuery } from 'react-query';
import getHistoryOrders from './history-orders';
import { IOrders } from '../../pages/profile-page/history';

interface HistoryOrdersProps {
  page: number;
  size: number;
}

export default function useHistoryOrders({ page, size }: HistoryOrdersProps) {
  const { data, refetch } = useQuery(
    ['historyOrder', page, size],
    () => getHistoryOrders(page, size),
    {
      keepPreviousData: true,
    }
  );
  return { data, refetch };
}
