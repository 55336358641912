import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, theme, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { HeartTwoTone, GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/authContext';

import SearchElem from '../UI/search-elem/search-elem';
import human from '../../icons/svg/human.svg';
import basketImg from '../../icons/svg/basket.svg';
import LoginModal from '../login-modal';
import LogoSvg from '../../icons/Logo';
import CityPickModal from '../city-pick-modal';
import './styles.scss';

export default function NavigationMenu() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    i18n.language.toUpperCase()
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { role } = useAuth();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const onOpen = () => {
    setIsOpen(true);
  };
  const onCancel = () => {
    setIsOpen(false);
  };

  const handleLanguageClick: MenuProps['onClick'] = (e) => {
    const newLang = e.key.toLowerCase();
    setSelectedLanguage(e.key);
    i18n.changeLanguage(newLang);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language.toUpperCase());
  }, [i18n.language]);

  const menuItems: MenuProps['items'] = [
    { key: 'RU', label: 'RU' },
    { key: 'KZ', label: 'KZ' },
  ];

  return (
    <>
      <div className="header-container">
        <div
          className="header-logo"
          onClick={() => navigate('/')}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigate('/');
            }
          }}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          aria-label="Go to home page"
        >
          <LogoSvg />
        </div>
        {/* <div style={{ display: 'flex', gap: '24px' }}> */}
        <div className="header-subcontainer">
          <SearchElem />
          <Button
            className="nav-btn"
            onClick={() => navigate('/')}
            style={{ color: token.colorPrimaryBg }}
          >
            Стать продавцом
          </Button>
        </div>
        {/*  <Input */}
        {/*    size="small" */}
        {/*    style={{ height: '48px', width: '581px' }} */}
        {/*    placeholder="Найти товары" */}
        {/*    prefix={<SearchOutlined />} */}
        {/*  /> */}
        {/* </div> */}
        <div className="content-header">
          <button
            className="nav-btn__menu"
            onClick={() => navigate('/favourites')}
            type="button"
          >
            <div className="header-icon-container">
              <HeartTwoTone twoToneColor="white" style={{ fontSize: '21px' }} />
            </div>
            Избранное
          </button>

          <button
            className="nav-btn__menu"
            onClick={() => navigate('/cart')}
            type="button"
          >
            <div className="header-icon-container">
              <span className="basket-count">{10}</span>
              <img src={basketImg} alt="basket" className="basket" />
            </div>
            Корзина
          </button>

          {role?.role === 'ROLE_CLIENT' ? (
            <button
              className="nav-btn__menu"
              onClick={() => navigate('/my-profile')}
              type="button"
            >
              <img src={human} alt="human" className="nav-btn__human" />
              Профиль
            </button>
          ) : (
            <button className="nav-btn__menu" onClick={onOpen} type="button">
              <img src={human} alt="human" className="nav-btn__human" />
              Войти
            </button>
          )}

          <CityPickModal />
        </div>

        <Dropdown
          menu={{ items: menuItems, onClick: handleLanguageClick }}
          trigger={['click']}
          placement="bottomCenter"
        >
          <div className="language-selector">
            <GlobalOutlined className="language-selector__icon" />
            <span className="language-selector__text">{selectedLanguage}</span>
            <DownOutlined className="language-selector__icon" />
          </div>
        </Dropdown>
      </div>
      <LoginModal isOpen={isOpen} onCloseModal={onCancel} />
    </>
  );
}
