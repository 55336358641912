import { message } from 'antd';
import { api } from '../api';

message.config({
  top: 100,
});
export default async function CategoriesLevelOne(page: number) {
  const response = await api.get(`/aman/getAll`, {
    params: {
      page,
      size: 20,
    },
  });
  return response.data;
}
