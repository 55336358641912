import { Typography, Image, Button, GlobalToken } from 'antd';
import '../styles.scss';
import { useNavigate } from 'react-router-dom';
import { IphoneImg } from '../../../icons';
import useHistoryOrders from '../../../api/history-orders/history-orders-hooks';
// import useHistoryOrders from '../../../api/history-orders/history-orders-hooks';

const items = [
  { name: 'Mon Amie', id: 1 },
  { name: 'Mon Amie', id: 2 },
  { name: 'Mon Amie', id: 3 },
  { name: 'Mon Amie', id: 4 },
  { name: 'Mon Amie', id: 5 },
];
interface FavouriteProductsProps {
  token: GlobalToken;
}

export interface FilialDTO {
  address: string;
  city?: string;
  created_date: string;
  id: number;
  index: string;
  name: string;
  update_date?: string | null;
}
export interface ProductDTO {
  amount?: number | null;
  balanceInStock: number;
  brand?: string | null;
  categoryDTOS?: Array<any> | null;
  colorCH: string;
  colorKZ: string;
  colorRU: string;
  createDate: string;
  deliveryId?: number | null;
  description?: string | null;
  favourites: boolean;
  finalPrice?: number | null;
  id: number;
  inBasket: boolean;
  inCart: boolean;
  name?: string | null;
  photoUrl: string[];
  pricePerPiece: number;
  status: boolean;
  subcategoryName?: string | null;
  updateDate?: string | null;
}

export interface IOrders {
  actual: boolean;
  amountOfProduct: number;
  comment?: string | null;
  customerId: number;
  customerType?: string | null;
  deliveryAddress?: string | null;
  filialDTO: FilialDTO;
  dayOffs: string;
  lunchBreak: string;
  name: string;
  workingHour: string;
  id: number;
  orderPrice: string;
  productDTO: ProductDTO;
  productId: number;
  productPrice: number;
  statusOfOrder: string;
}

export default function History({ token }: FavouriteProductsProps) {
  const { data } = useHistoryOrders({ page: 0, size: 10 });
  console.log(data);

  const navigate = useNavigate();

  const StatusStyle = (statusOfOrder: string) => {
    switch (statusOfOrder) {
      case 'Ожидается оплата':
        return 'orange';
        break;
      case 'Оплата принята':
        return 'green';
      default:
        return 'white';
        break;
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому добавляем 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}.${month}.${year}`;
  };

  return (
    <>
      <h2 className="history__title">История заказов</h2>
      <div className="history__container">
        {data
          ? data.content.map((item: IOrders) => (
              <div className="history__card" key={item.id}>
                <div className="history__card-info">
                  <div className="history__card-number">№{item.id}</div>
                  <div className="history__card-data">
                    {formatDate(
                      item.productDTO.updateDate ||
                        '2024-10-24T10:44:19.719+00:00'
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  className="history__card-container"
                  onClick={() =>
                    navigate(
                      `/category/subcategory/product/${item.productDTO.id}`
                    )
                  }
                >
                  <div className="history__content">
                    <div className="history__card-img-container">
                      <img
                        className="history__card-img"
                        alt="card-img"
                        src={item.productDTO.photoUrl[0] || IphoneImg}
                      />
                    </div>
                    <div className="history__card-text-content">
                      <div className="history__card-name">
                        {item.productDTO.name || 'Без названия'}
                      </div>
                      <div className="history__card-category">
                        {item.productDTO.subcategoryName || 'Смартфоны'}
                      </div>
                      <div className="history__card-price">
                        {item.productPrice} ₸
                      </div>
                    </div>
                  </div>

                  {/* <Button className="history__card-btn">детали заказа</Button> */}
                </button>
                <div
                  className="history__card-status"
                  style={{
                    backgroundColor: StatusStyle(
                      item.statusOfOrder || 'Waiting_payment'
                    ),
                  }}
                >
                  {item.statusOfOrder || 'Ожидается оплата'}
                </div>
              </div>
            ))
          : ''}
      </div>
    </>
  );
}
