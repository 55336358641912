import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Role } from '../types/role';
import getUserRole from './auth';

type AuthContextType = {
  role: Role | null;
  setRole: (role: Role | null) => void;
  login: (token: string) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [role, setRole] = useState<Role | null>(null);
  const login = (token: string) => {
    localStorage.setItem('ACCESS_TOKEN', token);
    const userRole = getUserRole();
    setRole(userRole);
  };
  const logout = () => {
    localStorage.removeItem('ACCESS_TOKEN');
    localStorage.removeItem('TOKEN_EXPIRY');
    setRole(null);
  };

  useEffect(() => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    if (token) {
      const userRole = getUserRole();
      setRole(userRole);
    }
  }, []);

  const value = useMemo(() => ({ role, setRole, login, logout }), [role]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth должен использоваться внутри AuthProvider');
  }
  return context;
};
