import React from 'react';
import './search-elem.scss';

import SearchIcons from '../../../icons/header-icons/search-icons';

function SearchElem() {
  return (
    <div className="search-elem">
      <input
        type="text"
        className="search-input"
        placeholder="Поиск по названию товара"
      />
      <button type="button" className="search-button" aria-label="Поиск">
        <SearchIcons />
      </button>
    </div>
  );
}

export default SearchElem;
