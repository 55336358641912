import {
  Button,
  Card,
  Carousel,
  Typography,
  Image,
  Checkbox,
  theme,
} from 'antd';

import { HeartFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { useMutation, UseQueryResult } from 'react-query';
import { useSelectedItems } from '../../../hooks/selectedItems';
// import ViewedProductCard from '../../../components/viewed-product-card';
import { BasketIcon, IphoneImg } from '../../../icons';
// import useViewedProducts from '../../../api/viewed-products/viewed-products-hooks';
// import { IViewedProducts } from '../../../types/viewed-products';
import noFoto from '../../../icons/assests/noFoto.png';
import { Order } from '../../../types/basket';
import {
  useDeleteAll,
  useDeleteOneItem,
  useUpdateBasketItems,
} from '../../../api/basket/basket-hooks';
import addToFavorite, {
  deleteFromFavorite,
} from '../../../api/favorite/favorite';
import useViewedProducts from '../../../api/viewed-products/viewed-products-hooks';
import ViewedProductCard from '../../../components/viewed-product-card';
import { IViewedProducts } from '../../../types/viewed-products';

interface NonEmptyCartProps {
  data?: Order[];
  refetch: UseQueryResult<Order[], unknown>['refetch'];
}

export default function NonEmptyCart({
  data = [],
  refetch,
}: NonEmptyCartProps) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const { selectedItems, setSelectedItems } = useSelectedItems();
  const [selectedItemsData, setSelectedItemsData] = useState<
    { id: number; amount: number }[]
  >([]);
  const { data: ViewedProducts, refetch: RefetchViewed } = useViewedProducts();
  const { mutate } = useUpdateBasketItems();
  const { mutate: DeleteOneItem } = useDeleteOneItem();
  const { mutate: DeleteAllItems } = useDeleteAll();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleUpdateAmount = (id: number, amount: number) => {
    if (amount >= 1) {
      mutate({ id, amount }, { onSuccess: () => refetch() });
      setSelectedItemsData((prev) => {
        const updatedData = prev.map((item) =>
          item.id === id ? { ...item, amount } : item
        );
        return updatedData.some((item) => item.id === id)
          ? updatedData
          : [...prev, { id, amount }];
      });
    }
  };

  const handleDeleteAll = () => {
    DeleteAllItems(undefined, {
      onSuccess: () => {
        setSelectedItems([]);
        setSelectedItemsData([]);
        refetch();
      },
    });
  };

  const handleDelete = (id: number) => {
    DeleteOneItem({ id }, { onSuccess: () => refetch() });
    setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
    setSelectedItemsData((prev) => prev.filter((item) => item.id !== id));
  };

  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const handleUpdate = (id: number) => {
    mutationAddToFavorite.mutate(id);
  };

  const handleDeleteFav = (id: number) => {
    deleteFromFavo.mutate(id);
  };

  const handleCheckboxChange = (id: number, checked: boolean) => {
    if (checked) {
      setSelectedItems((prev) => [...prev, id]);
      const itemAmount = data.find((item) => item.id === id)?.amount || 1;
      setSelectedItemsData((prev) => [...prev, { id, amount: itemAmount }]);
    } else {
      setSelectedItems((prev) => prev.filter((itemId) => itemId !== id));
      setSelectedItemsData((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelectedItems([]);
      setSelectedItemsData([]);
    } else {
      const allItemIds = data.map((item) => item.id);
      setSelectedItems(allItemIds);
      const allItemsData = data.map((item) => ({
        id: item.id,
        amount: item.amount,
      }));
      setSelectedItemsData(allItemsData);
    }
    setSelectedAll(!selectedAll);
  };
  const formatProductText = (qty: number) => {
    if (qty === 1) return 'товар';
    if (qty > 1 && qty < 5) return 'товара';
    return 'товаров';
  };

  useEffect(() => {
    setSelectedAll(data.length > 0 && selectedItems.length === data.length);
  }, [selectedItems, data]);

  const totalSum =
    data
      ?.filter((item) => selectedItems.includes(item.id))
      .reduce((sum, item) => sum + item.price * item.amount, 0) || 0;

  const totalQty =
    data
      ?.filter((item) => selectedItems.includes(item.id))
      .reduce((sum, item: Order) => sum + item.amount, 0) || 0;

  return (
    <div className="cart-container">
      <div className="cart-title">
        <div className="cart-selector">
          <Typography.Title className="cart-text">Корзина</Typography.Title>
          <button
            type="button"
            className="cart-select-all"
            onClick={handleSelectAll}
          >
            <Checkbox
              className="custom-checkbox"
              checked={selectedAll}
              style={
                isMobile
                  ? { display: 'flex', marginRight: '12px' }
                  : { display: 'none' }
              }
            />
            Выбрать все
          </button>
        </div>
        <Button
          style={{ backgroundColor: token.colorPrimaryBg }}
          className="cart-button"
          onClick={handleDeleteAll}
          disabled={data.length === 0}
        >
          Очистить корзину
        </Button>
      </div>

      <div className="grid1">
        <Card key={98} className="left">
          {data
            ?.sort((a, b) => a.id - b.id)
            .map((item: Order) => (
              <div
                key={item.id}
                className="out-of-stock-item column"
                style={{ position: 'relative' }}
              >
                <div className="row">
                  <Checkbox
                    className="custom-checkbox"
                    checked={selectedItems.includes(item.id)}
                    onChange={(e) =>
                      handleCheckboxChange(item.id, e.target.checked)
                    }
                  />
                  <Image
                    src={item.photoUrl?.[0]?.imageUrl || noFoto}
                    preview={false}
                    className="cart-product-img"
                    style={{ width: '60px', height: '60px' }}
                  />
                  <div className="column">
                    <div className="cart-product-name">{item.productName}</div>
                    <div className="cart-product-category">{item.category}</div>
                    <Typography className="cart-product-price">
                      {item.price}₸
                    </Typography>
                  </div>
                </div>
                <div className="space-between">
                  <Typography className="black-s">{item.brand}</Typography>
                  <div className="row">
                    {item.favourites ? (
                      <Button
                        onClick={() => handleDeleteFav(item.id)}
                        className="favorite-button"
                        style={{ color: '#B71B24' }}
                      >
                        <HeartFilled />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleUpdate(item.id)}
                        className="favorite-button"
                        style={{ color: '#100e71' }}
                      >
                        <HeartFilled />
                      </Button>
                    )}
                    <Image src="" style={{ color: 'blue' }} preview={false} />
                    <Image
                      onClick={() => handleDelete(item.id)}
                      src={BasketIcon}
                      preview={false}
                    />
                    <div className="basket-wrap">
                      <button
                        type="button"
                        onClick={() => {
                          handleUpdateAmount(item.id, item.amount - 1);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleUpdateAmount(item.id, item.amount - 1);
                          }
                        }}
                        disabled={item.amount <= 1}
                        style={{
                          border: 'none',
                          background: 'none',
                          color: '#999999',
                          fontSize: '25px',
                        }}
                      >
                        −
                      </button>
                      <span style={{ width: '50%' }}>{item.amount}</span>
                      <button
                        type="button"
                        onClick={() =>
                          handleUpdateAmount(item.id, item.amount + 1)
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === ' ') {
                            handleUpdateAmount(item.id, item.amount + 1);
                          }
                        }}
                        style={{
                          border: 'none',
                          background: 'none',
                          color: '#999999',
                          fontSize: '25px',
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Card>

        <Card className="center">
          <Typography.Text style={{ color: '#AAADB2', fontSize: '16px' }}>
            сумма к оплате
          </Typography.Text>
          <Typography className="price">
            {totalSum > 0 ? totalSum.toLocaleString() : '0'}{' '}
            <span className="grey-bold">₸</span>
          </Typography>
          <div className="row-center">
            <div className="grey-bold" style={{ fontSize: '18px' }}>
              {totalSum > 0 ? `${totalSum.toLocaleString()}₸` : '0₸'}
              <p className="without-margin">товары</p>
            </div>
          </div>
          <Button
            className="primary-button"
            disabled={selectedItems.length === 0}
            onClick={() => {
              navigate('/cart-add', {
                state: { selectedItemsData },
              });
            }}
          >
            Оформить
          </Button>
        </Card>
      </div>
      <Card className="full-width">
        <Typography className="card-header">Ничего не забыли?</Typography>
        <div className="margin-top">
          <Carousel
            arrows={!isMobile}
            draggable={isMobile}
            slidesToShow={isMobile ? 3 : 5}
            autoplay
            autoplaySpeed={4000}
            dots={false}
            className="main-carousel"
            nextArrow={
              <Button style={{ padding: '20px' }}>
                <RightOutlined
                  style={{
                    color: 'black',
                    fontSize: '20px',
                  }}
                />
              </Button>
            }
            prevArrow={
              <Button style={{ padding: '20px' }}>
                <LeftOutlined
                  style={{
                    color: 'black',
                    fontSize: '20px',
                  }}
                />
              </Button>
            }
          >
            {ViewedProducts?.content?.map((product: IViewedProducts) => (
              <ViewedProductCard
                refetch={RefetchViewed}
                key={product.id}
                product={product}
              />
            ))}
          </Carousel>
        </div>
      </Card>
      <div className="row-center-1">
        <div className="brush-content">
          <div className="sum-div">
            <div className="price column">
              {totalQty > 0 && (
                <span className="grey-bold">
                  {totalQty} {formatProductText(totalQty)}
                </span>
              )}

              <span className="grey-bold1">
                Итого{' '}
                <span className="black-bold1" style={{ fontSize: '20px' }}>
                  {totalSum > 0 ? totalSum.toLocaleString() : '0'}
                </span>{' '}
                ₸
              </span>
            </div>
            <button
              type="button"
              className="primary-button1 cart-finally-btn"
              disabled={selectedItems.length === 0}
              onClick={() => {
                navigate('/cart-add', {
                  state: { selectedItemsData },
                });
              }}
            >
              Оформить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

NonEmptyCart.defaultProps = {
  data: [],
};
