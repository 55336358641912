import { Button, Form, Input, message, theme, Typography } from 'antd';
import InputMask from 'react-input-mask';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginUser } from '../../../api/auth/auth-hooks';
import { useAuth } from '../../../hooks/authContext';

type Props = {
  onCreateAccount: () => void;
  onCloseModal: () => void;
};
message.config({
  top: 100,
});

export default function LoginForm({ onCreateAccount, onCloseModal }: Props) {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { mutate } = useLoginUser();
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  const onSubmit = () => {
    const phone = form.getFieldValue('phone').replace(/[+() \\-]/g, '');
    const password = form.getFieldValue('password');
    mutate(
      { password, phoneNumber: phone },
      {
        onSuccess: (data) => {
          if (data.token !== null) {
            login(data.token);
            message.success('Успешный вход!');
            navigate('/my-profile');
            onCloseModal();
          } else {
            message.error('Неправильный номер телефона или пароль');
          }
        },
        onError: (error: any) => {
          message.error(
            `Ошибка входа: ${error.response?.data?.message || error.message}`
          );
        },
      }
    );
  };
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={4}>Войти</Typography.Title>
      </div>

      <Form form={form}>
        {/* <Form.Item */}
        {/*  name="email" */}
        {/*  rules={[{ required: true, message: 'Введите email!' }]} */}
        {/*  style={{ margin: '0' }} */}
        {/* > */}
        {/*  <Input placeholder="Email" className="register-form__input" /> */}
        {/* </Form.Item> */}
        <Form.Item name="phone" style={{ margin: '0' }}>
          <InputMask
            mask="+7 (999) 999-99-99"
            className="register-form__input-number"
            placeholder="Номер телефона"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Введите пароль!' }]}
          style={{ margin: '0' }}
        >
          <Input placeholder="Пароль" className="register-form__input" />
        </Form.Item>
        <div
          style={{
            borderTop: '1px solid black',
            textAlign: 'center',
            paddingTop: '10px',
            marginTop: '20px',
          }}
        >
          Забыли пароль?
        </div>
      </Form>
      <Button
        className="register-form__submit-btn"
        onClick={onSubmit}
        style={{
          backgroundColor: token.colorPrimaryBg,
          border: token.colorPrimaryBg,
        }}
      >
        Войти
      </Button>
      <Button
        className="register-form__back-btn"
        style={{
          borderColor: token.colorPrimaryBg,
          color: token.colorPrimaryBg,
          fontWeight: '500',
        }}
        onClick={onCreateAccount}
      >
        Cоздать аккаунт
      </Button>
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        Создать аккаунт После регистрации вы получите доступ ко всем
        возможностям Aman Market
      </div>
      <div
        style={{
          width: '100',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Link
          to="/user-agreement"
          onClick={() => onCloseModal()}
          className="className"
        >
          Пользоват. соглашение
        </Link>
        <Link
          to="/public-contract-offer"
          onClick={() => onCloseModal()}
          className="className"
        >
          Публичный договор (оферта)
        </Link>
      </div>
    </div>
  );
}
