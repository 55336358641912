import { Image, Rate, theme, Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

export default function RetailerInfo() {
  const { token } = theme.useToken();
  return (
    <div className="retailer-info">
      <div style={{ padding: '24px' }}>
        <div className="mobile-row-wrap">
          <Image
            width="160px"
            style={{ borderRadius: '50%' }}
            src="https://yt3.googleusercontent.com/4BGR3tXicXD9bFDOsOoRv_5dW7AGfg3yOhVPjPSrCW5OgirQXfMhHnzSQTPFh2ogMyie84-BUQ=s900-c-k-c0x00ffffff-no-rj"
          />
          <div className="mobile-column-wrap">
            <div>
              <Typography.Title
                level={4}
                style={{ margin: 0, marginTop: '10px' }}
              >
                Mon Amie
              </Typography.Title>
              <div style={{ display: 'flex', gap: ' 20px' }}>
                <Rate value={4.5} />
                <Typography.Title level={5} style={{ margin: 0 }}>
                  4.5/5
                </Typography.Title>
              </div>
            </div>
            <Typography.Title
              level={5}
              style={{ margin: 0, marginTop: '10px' }}
            >
              <PhoneOutlined
                style={{
                  color: token.colorPrimaryBg,
                }}
              />{' '}
              +7(707)234-04-00
            </Typography.Title>
          </div>
        </div>
        <div
          style={{
            border: `1px solid ${token.colorPrimaryBg}`,
            borderRadius: '15px',
            marginTop: '20px',
            padding: '10px',
          }}
        >
          <Typography.Title
            level={5}
            style={{ margin: 0, textAlign: 'center' }}
          >
            85% рейтинг продавца
          </Typography.Title>
        </div>
        <div
          style={{
            border: `1px solid ${token.colorPrimaryBg}`,
            borderRadius: '15px',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          <Typography.Title
            level={5}
            style={{ margin: 0, textAlign: 'center' }}
          >
            5 тыс. заказов доставлено
          </Typography.Title>
        </div>
      </div>
    </div>
  );
}
