import './style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useState } from 'react';
import successImg from '../../icons/successful.png';
import { useAddOrderBasket } from '../../api/basket/basket-hooks';

interface SelectedItem {
  id: number;
  amount: number;
}
export default function CartAdd() {
  const { state } = useLocation();
  const selectedItemsData = state?.selectedItemsData || [];
  const { mutateAsync } = useAddOrderBasket();
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleBasket = async () => {
    try {
      const productIds = selectedItemsData.map((item: SelectedItem) => item.id);
      const amount = selectedItemsData.map((item: SelectedItem) => item.amount);

      await mutateAsync({ productIds, amount });
      setIsSuccess(true);
    } catch (error) {
      message.error('Возникла ошибка');
    }
  };

  return (
    <div className="container-cart">
      {!isSuccess ? (
        <div className="content">
          <div className="selector">
            <div className="">
              {/* <p>Город</p> */}
              <p className="city">Алматы</p>
            </div>
            <div>
              <p className="city">Бокейхана 510Б</p>
            </div>
            <div>
              <input className="city" placeholder="Комментарий к заказу" />
            </div>
          </div>
          <div className="div-button">
            <button type="button" className="button-div" onClick={handleBasket}>
              Создать
            </button>
          </div>
        </div>
      ) : (
        <div className="success">
          <div className="img-div">
            <img className="image-style" src={successImg} alt="success" />
            <p className="text-style">
              Ваш заказ успешно оформлен. Можете забрать его по адресу г. Алматы
              по адресу Бокейхана 510Б
            </p>
          </div>
          <div className="btn-div">
            <button
              onClick={() => navigate('/my-profile')}
              type="button"
              className="div-btn"
            >
              К вашим заказам
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
