import React from 'react';
import { useNavigate } from 'react-router-dom';

function PublicContractOffer() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: '100vw',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '18px',
        lineHeight: '1.6',
        margin: '0',
        padding: '20px',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: 'auto',
          backgroundColor: '#f9f9f9',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>
          ПУБЛИЧНЫЙ ДОГОВОР
          <br />
          ПОЛЬЗОВАНИЯ УСЛУГАМИ ПРЕДЛАГАЕМЫМИ ВЕБ-САЙТОМ КОМПАНИИ
          <br />
          «АТ Logistics»
        </h1>

        <h2>Глава 1. ОБЩИЕ УСЛОВИЯ</h2>
        <p>
          1. Перечисленные положения и условия определяют и регулируют
          правоотношения, возникающие у Сторон при предоставлении услуг (далее -
          Услуги), предусмотренных веб-сайтом{' '}
          <a href="https://amantrans.com.kz/">https://amantrans.com.kz/</a>{' '}
          (далее - Сайт) компании «АТ Logistics» ИИН/БИН 730805399047 (далее -
          Компания). Компания предоставляет Услуги в других странах с помощью
          своих партнеров.
        </p>
        <p>
          Осуществляя вход на Сайт Компании, пользуясь любыми Услугами Сайта или
          загружая как пользователь и (или) посетитель, (далее - Пользователь)
          любые сведения о себе полностью или частично, а также иную информацию,
          необходимую для оказания Услуги, Пользователь подтверждает, что
          полностью прочитал, ознакомлен и соглашается с публичным договором
          пользования веб-сайтом (далее - Договор), с его условиями, положениями
          и безоговорочно принимает их.
        </p>
        <p>
          2. Компания оставляет за собой право без предварительного уведомления,
          в одностороннем порядке, в любой момент изменить условия,
          предусмотренные Договором. При изменении условий, Компания размещает
          на Сайте новые условия Договора, при этом отметив дату последних
          внесенных изменений. Кроме этого, Компания не обязана уведомлять
          Пользователей Сайта о вносимых или внесенных изменениях, которые
          являются обязанностью Пользователя, в связи с чем, последний обязуется
          самостоятельно посещать сайт и проверять изменения и актуальность
          условий Договора. Новые условия Договора будут иметь юридическую силу
          и будут распространяться на обязательства Сторон со дня размещения на
          Сайте.
        </p>
        <p>
          3. В случае несогласия Пользователя с перечисленными условиями
          Договора, Пользователь вправе отказаться от использования Сайта.
        </p>
        <p>
          <strong>
            Пребывание на сайте, открытие счета, регистрация в качестве
            Пользователя и использование услуг Компании является согласием
            (акцептом) Пользователя и подтверждением, что Пользователь
            безоговорочно принимает условия Договора.
          </strong>
        </p>

        <h2>Глава 2. ДЕЕСПОСОБНОСТЬ ПРИ ПОДПИСАНИИ ДОГОВОРА</h2>
        <p>
          4. Соглашаясь с условиями Договора, Пользователь подтверждает, что в
          соответствии с законами страны Пользователя, полностью дееспособен и
          имеет право пользоваться Услугами Компании. Если по законам своей
          страны Пользователь является ограниченно дееспособным и не может
          самостоятельно пользоваться услугами Компании, то Пользователь должен
          получить согласие родителей или других законных представителей, чтобы
          воспользоваться услугами Компании. В случае, если Пользователь
          представляет интересы юридического лица, то он обязан удостоверить
          наличие документов, подтверждающих его полномочия, предоставляемые
          юридическим лицом, а также пользоваться Услугами в соответствии с
          законами страны, где зарегистрировано юридическое лицо.
        </p>
        <p>
          5. Соглашаясь с настоящими условиями Договора, Пользователь принимает
          во внимание, что Компания не имеет возможности проверить информацию и
          сведения, предоставленные Пользователем на подтверждение их
          подлинности, в связи с чем, при любом нарушении со стороны
          Пользователя данного пункта, всю ответственность Пользователь несет
          самостоятельно.
        </p>
        <p>
          6. Право иностранных физических лиц на пользование Услугами Компании:
          в соответствии с Кодексом РК «О таможенном регулировании в Республике
          Казахстан», иностранное физическое лицо может выступать декларантом в
          отношении товаров для личного пользования только при документальном
          подтверждении своего постоянного или временного проживания на
          территории Республики Казахстан.
        </p>
        <p>
          7. Перечень документов, один из которых обязано предоставить
          иностранное физическое лицо для таможенного оформления:
        </p>
        <ul>
          <li>Вид на жительство</li>
          <li>Удостоверение беженца</li>
          <li>Справка о временной регистрации иностранца</li>
          <li>Удостоверение лица без гражданства</li>
          <li>Виза, выданная загранучреждениями Республики Казахстан</li>
        </ul>
        <p>
          В случае отсутствия указанных документов, Компания оставляет за собой
          право отказа в предоставлении услуг и таможенном оформлении товара.
          При этом, при превышении порога на беспошлинный ввоз товара для
          личного пользования, иностранное физическое лицо обязуется
          предоставить Компании индивидуальный идентификационный номер (ИИН),
          присвоенный на территории Республики Казахстан.
        </p>

        {/* Продолжение текста с соответствующими заголовками, параграфами и списками */}
        {/* Вы можете продолжить добавлять остальные главы и пункты, следуя той же структуре */}

        {/* ... */}

        {/* Кнопка "Назад" */}
        <button
          type="button"
          onClick={() => navigate(-1)}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            cursor: 'pointer',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
          }}
        >
          Назад
        </button>
      </div>
    </div>
  );
}

export default PublicContractOffer;
