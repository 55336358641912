import { Typography, Image, Button } from 'antd';
import '../styles.scss';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import React from 'react';
import { useMutation } from 'react-query';
import { IphoneImg } from '../../../icons';
import { useFavouriteProducts } from '../../../api/products/products-hooks';
import { ContentItemProductDTO } from '../../../types/fav-my-profile';
import Spinner from '../../../components/UI/Spinner';
import addToFavorite, {
  deleteFromFavorite,
} from '../../../api/favorite/favorite';

export default function FavouriteProducts() {
  const { data, isError, isLoading, refetch } = useFavouriteProducts();
  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });
  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });
  const handleUpdate = (id: number) => {
    mutationAddToFavorite.mutateAsync(id);
  };
  const handleDelete = (id: number) => {
    deleteFromFavo.mutateAsync(id);
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return <div>У вас нет избранных заказов добавьте пожлауйста</div>;
  }

  interface ContentItemProductDTO {
    productDTO: {
      id: number;
      name: string;
      description?: string;
      subcategoryName?: string;
      price?: number;
      photoUrl?: string[];
      favourites?: boolean;
    };
  }

  return (
    <>
      <h2 className="fav-products__title">Избранные</h2>
      <div className="fav-products__container">
        {data.content.map((item: ContentItemProductDTO) => (
          <div className="fav-products__item" key={item.productDTO.id}>
            <div className="fav-products__img-container">
              <img
                className="fav-products__img"
                alt="poduct_img"
                src={
                  item.productDTO.photoUrl &&
                  item.productDTO.photoUrl.length > 0
                    ? item.productDTO.photoUrl[0]
                    : ''
                }
              />
            </div>
            <div className="fav-products__content">
              <div className="fav-products__text-content">
                <div className="fav-products__text-content__name">
                  {item.productDTO.description || 'Нет данных'}
                </div>
                <div className="fav-products__text-content__category">
                  {item.productDTO.subcategoryName || 'Нет данных'}
                </div>
                <div className="fav-products__text-content__store">
                  {item.productDTO.name || 'Нет данных'}
                </div>
                <div className="fav-products__text-content__price">
                  {item.productDTO.price
                    ? `${item.productDTO.price.toLocaleString()} ₸`
                    : 'Нет данных'}
                </div>
              </div>

              <Button
                className="fav-products__favorite-btn"
                style={{ color: 'red', fontSize: '30px' }}
                onClick={() => handleDelete(item.productDTO.id)}
              >
                <HeartFilled />
              </Button>
            </div>
            {/* {item.onBag ? ( */}
            {/*  <Button */}
            {/*    style={{ */}
            {/*      backgroundColor: '#E8E8E8', */}
            {/*      color: token.colorPrimaryBg, */}
            {/*      border: 'none', */}
            {/*      borderRadius: '30px', */}
            {/*    }} */}
            {/*  > */}
            {/*    в корзине */}
            {/*  </Button> */}
            {/* ) : ( */}
            {/*  <div className="flex-column"> */}
            {/*    <Button */}
            {/*      style={{ */}
            {/*        backgroundColor: '#100E71', */}
            {/*        color: '#fff', */}
            {/*        border: 'none', */}
            {/*        borderRadius: '30px', */}
            {/*      }} */}
            {/*    > */}
            {/*      купить сейчас */}
            {/*    </Button> */}
            {/*    <Button */}
            {/*      style={{ */}
            {/*        backgroundColor: '#fff', */}
            {/*        color: '#100E71', */}
            {/*        border: '1px solid #100E71', */}
            {/*        borderRadius: '30px', */}
            {/*      }} */}
            {/*    > */}
            {/*      в корзину */}
            {/*    </Button> */}
            {/*  </div> */}
            {/* )} */}
          </div>
        ))}
      </div>
      {/* <div> */}
      {/*  <Typography.Title level={2}>Избранные</Typography.Title> */}
      {/*  {data?.content?.map((item: ContentItem) => ( */}
      {/*    <div key={item.id} style={{ display: 'flex', marginBottom: '20px' }}> */}
      {/*      <div style={{ maxWidth: '140px' }}> */}
      {/*        <Image width="80%" src={item.product.imageUrl || IphoneImg} /> */}
      {/*      </div> */}

      {/*      <div style={{ maxWidth: '50%' }}> */}
      {/*        <Typography.Title level={4} style={{ margin: '0' }}> */}
      {/*          {item.product.name} */}
      {/*        </Typography.Title> */}

      {/*        <Typography.Title */}
      {/*          level={4} */}
      {/*          style={{ */}
      {/*            color: '#999999', */}
      {/*            marginTop: '10px', */}
      {/*          }} */}
      {/*        > */}
      {/*          {item.product.category.categoryName} */}
      {/*        </Typography.Title> */}
      {/*        <Typography.Title level={4} style={{ marginTop: '10px' }}> */}
      {/*          {item.product.brand} */}
      {/*        </Typography.Title> */}
      {/*        <Typography.Title level={4} style={{ marginTop: '10px' }}> */}
      {/*          {item.product.price.toLocaleString()} ₸ */}
      {/*        </Typography.Title> */}
      {/*      </div> */}

      {/*    </div> */}
      {/*  ))} */}
      {/* </div> */}
    </>
  );
}
