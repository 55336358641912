import React from 'react';
import { Modal, Typography } from 'antd';
import './style.css';

interface AuthModalProps {
  visible: boolean;
  onClose: () => void;
}

function AuthModal({ visible, onClose }: AuthModalProps) {
  return (
    <Modal visible={visible} onCancel={onClose} footer={null} centered>
      <Typography.Title level={4} style={{ textAlign: 'center' }}>
        Вы не авторизованы
      </Typography.Title>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        Пожалуйста, зарегистрируйтесь или авторизуйтесь
      </Typography.Paragraph>
      {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}> */}
      {/*  <Button type="primary" onClick={onLogin}> */}
      {/*    Войти */}
      {/*  </Button> */}
      {/*  <Button onClick={onRegister}>Регистрация</Button> */}
      {/* </div> */}
    </Modal>
  );
}

export default AuthModal;
