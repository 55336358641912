import React, { useState, useEffect, useCallback } from 'react';
import { Button, Carousel, Image } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import PopularProductCard from '../../components/popular-product-card';
import useViewedProducts from '../../api/viewed-products/viewed-products-hooks';
import CategoryCardMain from '../../components/category-card-main';
import './styles.scss';
import logo from '../../icons/assests/pictureAman.png';
import { IViewedProducts } from '../../types/viewed-products';
import ViewedProductCard from '../../components/viewed-product-card';
import useHomePageProducts, {
  useHomePageCategories,
} from '../../api/popular-products/homepage-products-hooks';
import { Category } from '../../types/categoties';
import AllCategory from '../../components/category-card-main/all-category';
import { useAuth } from '../../hooks/authContext';
import { PopularProduct } from '../../types/popular-products';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.4,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function MainPage() {
  const { role } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 800);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const { data: ViewedProducts, refetch: RefetchViewedProducts } =
    useViewedProducts();

  useEffect(() => {
    if (role?.role === 'ROLE_CLIENT' && ViewedProducts?.content?.length) {
      RefetchViewedProducts();
    }
  }, [role?.role, ViewedProducts?.content?.length, RefetchViewedProducts]);
  const { data: HomePageProducts, refetch: RefetchProducts } =
    useHomePageProducts();
  const { data: HomePageCategories } = useHomePageCategories();
  const popularProducts = HomePageProducts?.content || [];
  const categories = HomePageCategories?.content || [];

  const viewedProducts = ViewedProducts?.content || [];
  // const viewedProductsRepeated = Array.from(
  //   { length: 6 },
  //   () => viewedProducts
  // ).flat();

  return (
    <div className="main-body">
      <div className="main-wrap">
        <div>
          <div className="main-page__header-container">
            <div className="main-page__banner">
              <Image src={logo} alt="Logo" preview={false} />
            </div>
            {/* <div style={{ width: '100%', marginTop: '20px' }}> */}
            {/*  <CountrySwitch /> */}
            {/* </div> */}
          </div>
          <div className="main-page__category-list">
            <div style={{ width: '100%' }}>
              <Carousel
                swipeToSlide
                arrows={!isMobile}
                draggable={isMobile}
                slidesToShow={isMobile ? 4 : 5}
                autoplay
                autoplaySpeed={4000}
                dots={false}
                className="main-carousel-one"
                nextArrow={
                  <Button style={{ padding: '20px' }}>
                    <RightOutlined
                      style={{
                        color: 'black',
                        fontSize: '20px',
                      }}
                    />
                  </Button>
                }
                prevArrow={
                  <Button style={{ padding: '20px' }}>
                    <LeftOutlined
                      style={{
                        color: 'black',
                        fontSize: '20px',
                      }}
                    />
                  </Button>
                }
              >
                <AllCategory key="all-categories" name="Все категории" />

                {categories.map((category: Category) => (
                  <CategoryCardMain key={category.id} category={category} />
                ))}
              </Carousel>
            </div>
          </div>
          {role?.role === 'ROLE_CLIENT' && viewedProducts.length > 0 && (
            <div className="main-page__viewed-products">
              <h2 className="main-page__viewed-products__title">
                Вы недавно смотрели
              </h2>
              <div style={{ width: '100%' }}>
                <Carousel
                  arrows={!isMobile}
                  draggable={isMobile}
                  slidesToShow={isMobile ? 3 : 5}
                  autoplay
                  infinite
                  autoplaySpeed={4000}
                  dots={false}
                  className="main-carousel-two"
                  nextArrow={
                    <Button style={{ padding: '20px' }}>
                      <RightOutlined
                        style={{
                          color: 'black',
                          fontSize: '20px',
                        }}
                      />
                    </Button>
                  }
                  prevArrow={
                    <Button style={{ padding: '20px' }}>
                      <LeftOutlined
                        style={{
                          color: 'black',
                          fontSize: '20px',
                        }}
                      />
                    </Button>
                  }
                >
                  {viewedProducts.map((product: IViewedProducts) => (
                    <ViewedProductCard
                      key={uuidv4()}
                      refetch={RefetchViewedProducts}
                      product={product}
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          )}
          {popularProducts.length > 0 && (
            <div className="main-page__popular-products-container">
              <h2 className="main-page__popular-products-title">
                Популярные товары
              </h2>
              <motion.ul
                variants={container}
                initial="hidden"
                animate="visible"
                style={{ listStyle: 'none', padding: '0' }}
                className="main-page__popular-products-list"
              >
                {popularProducts.map((product) => (
                  <motion.li
                    className="popular-cards"
                    variants={item}
                    key={product.id}
                  >
                    <PopularProductCard
                      refetch={RefetchProducts}
                      product={product}
                    />
                  </motion.li>
                ))}
              </motion.ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainPage;
