import axios from 'axios';
import {
  ProductDetailType,
  Product,
  ProductCategoryDetailType,
} from '../../types/products';
import { api } from '../api';

export async function fetchProductDetail(): Promise<ProductDetailType> {
  return api.get(`/api/products/1`).then((response) => response.data);
}

export async function categoryProducts(
  categoryId: number | undefined,
  lang: string,
  page: number,
  size: number,
  priceFrom?: number,
  priceTo?: number,
  color?: string,
  name?: string
): Promise<ProductCategoryDetailType[]> {
  const response = await api.get(`/products`, {
    params: {
      categoryId,
      lang,
      page,
      size,
      priceFrom,
      priceTo,
      color,
      name,
    },
  });
  return response.data;
}
export async function getPopularProducts(): Promise<Product[]> {
  return api
    .get(`http://185.129.49.247:8060/productProducts`)
    .then((response) => response.data);
}

export async function getFavouriteProducts() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  const { data } = await api.get('/internal/favorites/all', {
    params: { page: 0, size: 100 },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
export async function getProductById(id: string | undefined) {
  const token = localStorage.getItem('ACCESS_TOKEN');

  const headers = token ? { Authorization: `Bearer ${token}` } : {};
  const { data } = await api.get(`/products/${id}`, { headers });
  return data;
}
