import React from 'react';

interface HomeIconsProps {
  svgClassName?: string;
}

export default function ProfileIcons({ svgClassName }: HomeIconsProps) {
  return (
    <svg
      className={svgClassName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 30"
    >
      <path d="M9.99112 23.7002C9.52722 23.7002 9.16272 23.5952 8.89763 23.3854C8.63254 23.1755 8.5 22.8883 8.5 22.5238C8.5 21.9881 8.66016 21.4276 8.98047 20.8422C9.30631 20.2568 9.77298 19.7073 10.3805 19.1937C10.9935 18.6801 11.7308 18.2631 12.5923 17.9428C13.4538 17.6225 14.4203 17.4623 15.4917 17.4623C16.5686 17.4623 17.5379 17.6225 18.3994 17.9428C19.2609 18.2631 19.9955 18.6801 20.603 19.1937C21.216 19.7073 21.6854 20.2568 22.0112 20.8422C22.3371 21.4276 22.5 21.9881 22.5 22.5238C22.5 22.8883 22.3675 23.1755 22.1024 23.3854C21.8373 23.5952 21.4728 23.7002 21.0089 23.7002H9.99112ZM15.5 15.9877C14.898 15.9877 14.343 15.8248 13.8349 15.499C13.3323 15.1731 12.9264 14.7341 12.6172 14.1818C12.3134 13.6296 12.1615 13.011 12.1615 12.3262C12.1615 11.6524 12.3134 11.0449 12.6172 10.5037C12.9264 9.95698 13.3351 9.52345 13.8432 9.20313C14.3513 8.88281 14.9036 8.72266 15.5 8.72266C16.102 8.72266 16.657 8.88005 17.1651 9.19485C17.6732 9.50964 18.0791 9.94041 18.3828 10.4872C18.6921 11.0284 18.8467 11.6359 18.8467 12.3096C18.8467 13 18.6921 13.624 18.3828 14.1818C18.0791 14.7341 17.6732 15.1731 17.1651 15.499C16.657 15.8248 16.102 15.9877 15.5 15.9877Z" />
    </svg>
  );
}
ProfileIcons.defaultProps = {
  svgClassName: '',
};
