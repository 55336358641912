import { useMutation, useQuery, useQueryClient } from 'react-query';
import getBasketItems, {
  addOrderBasket,
  deleteAllItem,
  deleteOneItem,
  updateAmountBasket,
} from './basket';

export default function useBasketItems() {
  const { data, refetch } = useQuery(['basketItems'], getBasketItems);

  return { data, refetch };
}

export function useUpdateBasketItems() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ amount, id }: { amount: number; id: number }) =>
      updateAmountBasket(amount, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('basketItems');
      },
    }
  );

  return mutation;
}
export function useDeleteOneItem() {
  const queryClient = useQueryClient();

  const mutation = useMutation(({ id }: { id: number }) => deleteOneItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries('basketItems');
    },
  });

  return mutation;
}
export function useDeleteAll() {
  const queryClient = useQueryClient();
  const mutation = useMutation(() => deleteAllItem(), {
    onSuccess: () => {
      queryClient.invalidateQueries('basketItems');
    },
  });

  return mutation;
}
export function useAddOrderBasket() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ productIds, amount }: { productIds: number[]; amount: number[] }) =>
      addOrderBasket(productIds, amount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('useAddOrderBasket');
      },
    }
  );

  return mutation;
}
