import React, { useContext, useState } from 'react';
import { MaskedInput } from 'antd-mask-input';
import { Button, Form, FormInstance, message, theme } from 'antd';
import { UserInfoContext } from '../index';
import '../styles.css';
import useCreateUser from '../../../api/auth/auth-hooks';

type IndividualFormProps = {
  setIsNext: React.Dispatch<React.SetStateAction<string>>;
  form: FormInstance<any>;
};
message.config({
  top: 100,
});
export default function IndividualForm({
  setIsNext,
  form,
}: IndividualFormProps) {
  const { token } = theme.useToken();
  const context = useContext(UserInfoContext);
  const { mutate } = useCreateUser();

  // Инициализируем phoneValue как пустую строку
  const [phoneValue, setPhoneValue] = useState('');

  const onNextStep = () => {
    const phoneNumberCheck = phoneValue.replace(/\D/g, '');

    context.setUser({
      fullName: '',
      password: '',
      phoneNumber: phoneNumberCheck,
      username: '',
      smsCode: '',
      cityId: '',
      filialId: '',
    });

    mutate(
      {
        lastName: '',
        password: '',
        phoneNumber: phoneNumberCheck,
        smsCode: '',
        fullName: '',
        cityId: '',
      },
      {
        onSuccess: (data) => {
          if (data.name === 'Пользователь с таким номером уже существует') {
            message.error(data.name);
            setIsNext('initial');
          } else {
            message.success(data.name);
            setIsNext('smsCode');
          }
        },
        onError: () => {
          message.error('Ошибка при отправке номера телефона');
        },
      }
    );
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneValue(e.target.value);
  };

  const validatePhoneNumber = (_: any, value: string) => {
    const cleanedValue = value.replace(/\D/g, ''); // Убираем все нечисловые символы
    if (cleanedValue.length !== 11 || !cleanedValue.startsWith('7')) {
      return Promise.reject(
        new Error(
          'Номер телефона должен начинаться с +7 и содержать 11 цифр. Пример: +7 (777) 777 77 77'
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <div style={{ marginTop: '30px', textAlign: 'left' }}>
      <Form form={form}>
        <Form.Item
          name="phone"
          rules={[
            { required: true, message: 'Введите номер телефона!' },
            { validator: validatePhoneNumber },
          ]}
          style={{ margin: '0' }}
          initialValue={phoneValue}
        >
          <MaskedInput
            mask="+{7} (000) 000 00 00"
            value={phoneValue}
            onChange={handlePhoneChange}
            placeholder="Введите номер телефона"
            className="register-form__input"
          />
        </Form.Item>

        <Button
          className="register-form__submit-btn"
          onClick={onNextStep}
          style={{
            backgroundColor: token.colorPrimaryBg,
            border: token.colorPrimaryBg,
          }}
        >
          Продолжить
        </Button>
      </Form>
    </div>
  );
}
