import { theme, Typography } from 'antd';

export default function NotFoundPage() {
  const { token } = theme.useToken();
  return (
    <div style={{ textAlign: 'center', marginBottom: '200px' }}>
      <Typography.Title
        style={{
          fontSize: '200px',
          color: token.colorPrimaryBg,
        }}
      >
        404
      </Typography.Title>
      <Typography.Title
        level={2}
        style={{
          color: token.colorPrimaryBg,
        }}
      >
        Страница не найдена 😭
      </Typography.Title>
    </div>
  );
}
