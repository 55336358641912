import { useMutation } from 'react-query';
import { UserLogin, UserRegister } from '../../types/register-types';
import { api } from '../api';

export default async function createUser({
  smsCode,
  lastName,
  password,
  phoneNumber,
  cityId,
  fullName,
}: UserRegister) {
  const { data } = await api.post(`/authmarket/sign-up`, {
    phone: phoneNumber,
    password,
    lastName,
    smsCode,
    fullName,
    username: '',
    filialId: null,
    cityId,
    pushStatus: '',
  });
  localStorage.setItem('ACCESS_TOKEN', data.token);
  return data;
}

export async function loginUser({ password, phoneNumber }: UserLogin) {
  const { data } = await api.post(`/authmarket/sign-in`, {
    phone: phoneNumber,
    password,
  });

  const tokenExpiry = new Date().getTime() + 365 * 24 * 60 * 60 * 1000;
  localStorage.setItem('ACCESS_TOKEN', data.token);
  localStorage.setItem('TOKEN_EXPIRY', tokenExpiry.toString());

  return data;
}
export function useLoginUser() {
  return useMutation(loginUser);
}

// Хук для регистрации пользователя
export function useCreateUser() {
  return useMutation(createUser);
}
