import { Button, Carousel, Image, theme, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import './styles.css';
import { CarouselRef } from 'antd/es/carousel';
import { QueryObserverResult, useMutation } from 'react-query';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { ProductById } from '../../../types/product-by-id';
import addBasket from '../../../api/basket/add-basket';
import addToFavorite, {
  deleteFromFavorite,
} from '../../../api/favorite/favorite';
import { PopularProduct } from '../../../types/popular-products';
import { useAuth } from '../../../hooks/authContext';
import AuthModal from '../../../components/UI/Modal';

type Props = {
  data: ProductById | null;
  refetch: any;
};

export default function ProductMainInfo({ data, refetch }: Props) {
  const { token } = theme.useToken();
  const { role } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const carouselRef = useRef<CarouselRef>(null);
  const handleImageClick = (index: number) => {
    if (carouselRef.current) {
      carouselRef.current.goTo(index);
    }
  };
  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });
  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });
  const handleDelete = (id: number) => {
    deleteFromFavo.mutateAsync(id);
  };
  const handleUpdate = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationAddToFavorite.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };
  const mutationBasket = useMutation((id: number) => addBasket(id));
  const addToBasketHandler = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationBasket.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="product__main-description">
      <AuthModal visible={isModalVisible} onClose={handleCloseModal} />
      <div className="product-main-info-div">
        <div className="product-carousel-images">
          {data?.photoUrl!.map((test, index) => (
            <Button
              key={`product-sub-image-${test}`}
              className="product__sub-image"
              style={{
                border: `2px solid ${token.colorPrimaryBg}`,
                cursor: 'pointer',
                background: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                padding: 0,
                width: '100px',
                height: '100px',
              }}
              onClick={() => handleImageClick(index)}
            >
              <Image
                src={test}
                preview={false}
                width="100%"
                height="100%"
                style={{
                  objectFit: 'contain',
                  overflow: 'hidden',
                }}
              />
            </Button>
          ))}
        </div>
        <div className="product-main-images" style={{ position: 'relative' }}>
          {data?.favourites === true ? (
            <button
              aria-label="Delete"
              type="button"
              onClick={() => handleDelete(data.id)}
              className="favorite-button product-in-favories"
            >
              <HeartFilled style={{ width: '100%', height: '100%' }} />
            </button>
          ) : (
            <button
              aria-label="В избранное"
              type="button"
              onClick={() => data && handleUpdate(data.id)}
              className="favorite-button"
            >
              <HeartOutlined style={{ width: '100%', height: '100%' }} />
            </button>
          )}
          <Carousel ref={carouselRef} className="custom-carousel">
            {data?.photoUrl?.map((imageUrl: string) => (
              <div
                key={imageUrl}
                className="carousel-slider-div-image"
                style={{
                  background: 'none',
                  overflow: 'hidden',
                  padding: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Image
                  src={imageUrl}
                  preview={false}
                  width="90%"
                  height="90%"
                  style={{
                    objectFit: 'contain',
                    overflow: 'hidden',
                  }}
                />
              </div>
            ))}
          </Carousel>
          {/* <Carousel className="custom-carousel" ref={carouselRef}> */}
          {/*  {data?.photoUrl!.map((imageUrl, index: number) => ( */}
          {/*    <div */}
          {/*      className="carousel-slider-div-image" */}
          {/*      key={index} */}
          {/*      style={{ objectFit: 'contain', height: '100%', width: '100%' }} */}
          {/*    > */}
          {/*      <Image src={imageUrl} preview={false} width="70%" /> */}
          {/*    </div> */}
          {/*  ))} */}
          {/* </Carousel> */}
        </div>
        <div className="product-information-sector">
          <div className="product-information-name">{data?.name}</div>
          <div className="product-information-seller">{data?.brand}</div>
          <div className="product-information-category">
            {data?.subcategoryName}
          </div>

          <Typography.Title
            level={5}
            style={{
              fontSize: '15px',
              overflow: 'hidden',
            }}
          >
            {data?.description}
            {/* <FullDescriptionFunction text={"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Vero, animi. Repellat sed reiciendis exercitationem eum laudantium, quo quasi culpa esse omnis itaque animi cum nesciunt praesentium hic eligendi nihil. Magni delectus ratione recusandae? Autem labore minima enim quisquam debitis nobis delectus pariatur impedit non praesentium deleniti sint, ut laborum quos!"} /> */}
          </Typography.Title>
          <div>
            {/* <Typography.Title level={4}>Рейтинг</Typography.Title> */}
            <div style={{ display: 'flex' }}>
              {/* <Rate disabled value={data?.rating ? undefined} /> */}
              {/* data?.rating */}
              <Typography.Title
                style={{
                  fontSize: '15px',
                  margin: '0 0 0 10px',
                }}
              >
                {/* {data?.rating}/10 */}
              </Typography.Title>
            </div>
          </div>
        </div>
        <div className="product__price-tag">
          <div className="product-information-price">
            {data?.pricePerPiece}₸
          </div>
          {/* <Typography.Title level={5} style={{ margin: 0 }}>
            доставка {data?.price_delivery} ₸

          </Typography.Title> */}
          <div style={{ display: 'flex', marginTop: '5%' }}>
            {/* <Rate disabled value={data?.rating || 0} /> */}
            <Typography.Title
              style={{ fontSize: '15px', margin: '0 0 0 10px' }}
            >
              {/* {data?.rating}/10 */}
            </Typography.Title>
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            {data?.inBasket === false ? (
              <Button
                className="product-to-basket-btn"
                onClick={() => addToBasketHandler(data?.id)}
                style={{ background: token.colorPrimaryBg }}
              >
                В корзину
              </Button>
            ) : (
              <p style={{}}>В корзине</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
