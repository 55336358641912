import { api } from '../api';

export default async function getCities() {
  const { data } = await api.get(`/city/get-all-cities`);

  return data;
}

export async function getFilials(cityId: string) {
  const { data } = await api.get(`/filials/by-city-id?cityId=${cityId}`);

  return data;
}
