import React from 'react';

import RetailerInfo from './retailer-info';
import './styles.css';
import RetailerProducts from './retailer-products';

export default function RetailerPage() {
  return (
    <div className="page-container">
      <RetailerInfo />
      <RetailerProducts />
    </div>
  );
}
