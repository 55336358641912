import React from 'react';
import { useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import { Card, Button, Image } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { QueryObserverResult, useMutation } from 'react-query';
import { IViewedProducts } from '../../types/viewed-products';
import addBasket from '../../api/basket/add-basket';
import addToFavorite, { deleteFromFavorite } from '../../api/favorite/favorite';
import './viewed-product-card.scss';

interface PopularProductCardProps {
  product: IViewedProducts;
  refetch: () => Promise<
    QueryObserverResult<{ content: IViewedProducts[] }, Error>
  >;
}
function ProductCard({ product, refetch }: PopularProductCardProps) {
  const navigate = useNavigate();

  const mutationBasket = useMutation((id: number) => addBasket(id), {
    onSuccess: () => refetch(),
    onError: () => refetch(),
  });
  const addToBasket = () => {
    mutationBasket.mutateAsync(product.id);
  };
  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => refetch(),
    onError: () => refetch(),
  });
  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => refetch(),
    onError: () => refetch(),
  });
  const handleUpdate = () => {
    mutationAddToFavorite.mutateAsync(product.id);
  };
  const handleDelete = () => {
    deleteFromFavo.mutateAsync(product.id);
  };
  const handleCardClick = () => {
    navigate(`/category/subcategory/product/${product.id}`);
  };

  return (
    <motion.div className="viewed-product-card">
      <Card
        onClick={handleCardClick}
        className="viewed-product-card__card"
        cover={
          <div className="viewed-product-card__cover">
            <Image
              src={product.photoUrl}
              alt="viewed-product__img"
              className="viewed-product-card__image"
              preview={false}
            />
            {product.favourites === true ? (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
                className="viewed-product-card__favorite-button"
                style={{ color: 'red' }}
              >
                <HeartFilled />
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleUpdate();
                }}
                className="viewed-product-card__favorite-button"
                style={{ color: '#000' }}
              >
                <HeartOutlined />
              </Button>
            )}
          </div>
        }
      >
        <div className="viewed-product-card__container">
          <div className="viewed-product-card__title">
            {product.name || 'no name'}
          </div>
          <div className="viewed-product-card__subcategory">
            {product.brand}
          </div>
          <div className="viewed-product-card__price">
            {product.pricePerPiece}₸
          </div>
        </div>
        {product.inBasket === true ? (
          <p className="viewed-product-card__cart-btn cart-btn-in">В корзине</p>
        ) : (
          <Button
            className="viewed-product-card__cart-btn"
            onClick={(e) => {
              e.stopPropagation();
              addToBasket();
            }}
          >
            в корзину
          </Button>
        )}
      </Card>
    </motion.div>
  );
}

export default ProductCard;
