import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import locationOutline from '../../icons/svg/location-outline.svg';

const allCities = [
  'Абай (Караганд.обл)',
  'Айтеке Би',
  'Айша-Биби (Жамб.Обл)',
  'Акколь',
  'Акмол (Малиновка)',
  'Аксай',
  'Аксу (Павлод.обл)',
  'Аксукент',
  'Актау',
  'Актобе',
  'Алга (Актюб.обл)',
  'Алдаберген',
  'Алматы',
  'Алтай',
  'Аральск',
  'Аркалык',
  'Арысь',
  'Астана',
  'Атбасар',
  'Атырау',
  'Аягоз',
  'Байдибек бия',
  'Байконыр',
  'Балпык Би',
  'Балхаш',
  'Бауржан Момышулы (Жамб.Обл)',
  'Бейнеу',
  'Белбулак',
  'Бесагаш',
  'Жанаарка',
  'Жанакорган',
  'Жанаозен',
  'Жанатас',
  'Жаркент',
  'Жезказган',
  'Жетыбай',
  'Жетысай',
  'Жибек-Жолы (Туркестан.обл)',
  'Житикара',
  'Жосалы',
  'Зачаганск',
  'Индербор',
  'Иргели',
  'Кабанбай (Алмат.Обл)',
  'Кабанбай батыр (Акмол. Обл)',
  'Казыгурт',
  'Кандыагаш',
  'Карабалык',
  'Карабулак',
  'Караганда',
  'Каражар (Акмол.Обл)',
  'Караоткель',
  'Каратау',
  'Каркаралинск',
  'Каскелен',
  'Касыма Кайсенова',
  'Качар',
  'Кенжеколь',
  'Кульсары',
  'Курмангазы',
  'Курчатов',
  'Кызылорда',
  'Ленгер',
  'Ленинский',
  'Лисаковск',
  'Макат',
  'Макинск',
  'Мамлютка',
  'Мангистау',
  'Мартук',
  'Махамбет',
  'Мерке',
  'Мухаметжан Туймебаева',
  'Новоишимское (СКО)',
  'Отар',
  'Отеген батыр',
  'Павлодар',
  'Павлодарское',
  'Панфилово',
  'Петропавловск',
  'Подстепное',
  'Приозeрск',
  'Райымбек',
  'Риддер',
  'Рудный',
  'Сарань',
  'Сарканд',
  'Сарыагаш',
  'Сарыкемер',
  'Талдыкорган',
  'Тараз',
  'Тасбогет',
  'Текели',
  'Темиртау',
  'Теренозек',
  'Тобыл',
  'Толе би (Жамбыл. обл)',
  'Топар',
  'Туздыбастау',
  'Турар Рыскулов',
  'Туркестан',
  'Узынагаш',
  'Уральск',
  'Усть-Каменогорск',
  'Ушарал',
  'Уштобе',
  'Федоровка (Кост.Обл)',
  'Хромтау',
  'Чапаево',
  'Чунджа',
  'Шалкар (Актюб.обл)',
  'Шардара',
  'Шахан',
  'Шахтинск',
  'Шелек',
  'Шемонаиха',
  'Шетпе',
];
export default function CityPickModal() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onChangeCity = (city: string) => {
    localStorage.removeItem('city');
    localStorage.setItem('city', city);
    window.location.reload();
  };

  return (
    <div>
      <button
        className="nav-btn__menu-location"
        onClick={onOpen}
        style={{ width: '100px' }}
        type="button"
      >
        <img src={locationOutline} alt="location" />
        {localStorage.getItem('city') || 'Алматы'}
      </button>
      <Modal
        open={isOpen}
        onCancel={onClose}
        footer={false}
        width="892px"
        style={{ maxHeight: '767px' }}
      >
        <div>
          <Typography.Title
            style={{ textAlign: 'center', marginBottom: '50px' }}
          >
            Выберите ваш город
          </Typography.Title>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
          }}
        >
          {allCities.map((city) => (
            <Typography.Title
              style={{
                fontSize: '14px',
                width: '33%',
                margin: '0',
                fontWeight: '400',
                cursor: 'context-menu',
                marginBottom: '5px',
              }}
              key={city}
              onClick={() => onChangeCity(city)}
            >
              {city}
            </Typography.Title>
          ))}
        </div>
      </Modal>
    </div>
  );
}
