import React from 'react';

import { Card, Typography } from 'antd';
import './category-card-categories.css';
import { useNavigate } from 'react-router-dom';

type Category = {
  id: number;
  categoryName: string;
  categoryPath?: string | null;
  createdAt?: string;
  description?: string | null;
  imageUrl?: string | undefined;
  level?: number;
  parentCategoryId?: number | null;
  shortDescription?: string | null;
  status?: boolean | null;
  updatedAt?: string | null;
};

type CategoryCardProps = {
  name: string;
  category?: Category;
};

export default function CategoryCardCategories({
  name,
  category,
}: CategoryCardProps) {
  const navigate = useNavigate();

  return (
    <Card
      onClick={() => navigate(`/category/subcategory/products/${category?.id}`)}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.key === ' ') {
          navigate(`/category/subcaterogy/products/${category?.id}`);
        }
      }}
      role="button"
      tabIndex={0}
      className="category-card"
      style={{
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '600',
      }}
    >
      <div className="category-main__card-title">
        <Typography.Title level={4} className="category-title">
          {category?.categoryName || 'No Category Name'}
        </Typography.Title>
      </div>
      <img
        alt={name}
        className="category-image"
        // src={
        //   category?.imageUrl ||
        //   'https://png.pngtree.com/png-clipart/20230506/original/pngtree-smiling-dogs-with-happy-expressions-png-image_9144389.png'
        // }
        src={category?.imageUrl}
      />
    </Card>
  );
}

CategoryCardCategories.defaultProps = {
  category: undefined,
};
