import axios from 'axios';

export const getAccessToken = (): string => {
  return localStorage.getItem('ACCESS_TOKEN') || '';
};
const baseURL = process.env.REACT_APP_API_BASE_URL || '';
export const api = axios.create({
  baseURL,
});
// http://185.129.49.247:8060/
// api.interceptors.request.use(
//   (config) => {
//     const token = getAccessToken();
//     if (token) {
//       // eslint-disable-next-line no-param-reassign
//       config.headers!.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );
//
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if ([401].includes(error.response?.status)) {
//       window.location.href = "/auth";
//     }
//     return Promise.reject(error);
//   }
// );
