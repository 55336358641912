import React from 'react';
import { Button, Carousel, theme, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ViewedProductCard from '../../../components/viewed-product-card';
import '../styles.css';
import { IViewedProducts } from '../../../types/viewed-products';

import useViewedProducts from '../../../api/viewed-products/viewed-products-hooks';

// const reviews: Review[] = [
//   {
//     id: 1,
//     comment:
//       'Достоинства: Очень быстро заряжает. Комментарий: Но Apple все равно плохие, в телефон за 700 тысяч не положить блок для зарядки, это ужас конечно.',
//     rating: 5,
//     created_date: '2020-09-14',
//     customer_ind: {
//       full_name: 'Амир',
//     },
//     customer_entity: { username: 'frf' },
//     images: ['1', '2'],
//   },
//   {
//     id: 1,
//     comment:
//       'Достоинства: Очень быстро заряжает. Комментарий: Но Apple все равно плохие, в телефон за 700 тысяч не положить блок для зарядки, это ужас конечно.',
//     rating: 5,
//     created_date: '2020-09-14',
//     customer_ind: {
//       full_name: 'Амир',
//     },
//     customer_entity: { username: 'frf' },
//     images: ['1', '2'],
//   },
// ];

export default function RetailerProducts() {
  const { data: ViewedProducts, refetch: RefetchViewed } = useViewedProducts();
  const { token } = theme.useToken();
  return (
    <div className="products-container">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title level={2} style={{ margin: 0 }}>
          Товары продавца
        </Typography.Title>
        <Button
          style={{
            backgroundColor: token.colorPrimaryBg,
            color: 'white',
            borderRadius: '24px',
          }}
        >
          посмотреть все
        </Button>
      </div>
      <div style={{ marginTop: '20px' }}>
        <Carousel
          arrows
          autoplay
          autoplaySpeed={4000}
          dots={false}
          slidesToShow={4}
          // style={{ zoom: '75%' }}
          nextArrow={
            <Button style={{ padding: '!20px' }}>
              <RightOutlined style={{ color: 'black', fontSize: '20px' }} />
            </Button>
          }
          prevArrow={
            <Button style={{ padding: '20px' }}>
              <LeftOutlined style={{ color: 'black', fontSize: '20px' }} />
            </Button>
          }
        >
          {ViewedProducts?.content?.map((product: IViewedProducts) => (
            <ViewedProductCard
              refetch={RefetchViewed}
              key={product.id}
              product={product}
            />
          ))}
        </Carousel>
      </div>
      <div>{/* <ProductReviews data={reviews} /> */}</div>
    </div>
  );
}
