import { Avatar, Button, message, theme, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckIcon, BoxIcon, StarIcon, NextArrowIcon } from '../../icons';
import FavouriteShops from './fav-shops';
import FavouriteProducts from './fav-products';
import History from './history';
import useMyProfile from '../../api/my-profile/my-profile-hooks';
import Spinner from '../../components/UI/Spinner';
import { useAuth } from '../../hooks/authContext';
import EditProfile from './edit-profile';

import avatar from '../../icons/svg/Avatar.svg';
import './styles.scss';

message.config({
  top: 100,
});
const sidebarItems = [
  { name: 'История заказов', link: 'history', icon: CheckIcon },
  { name: 'Избранные', link: 'favourites', icon: BoxIcon },
  { name: 'Любимые магазины', link: 'shops', icon: StarIcon },
  // { name: 'Мои возвраты', link: 'returns', icon: BackIcon },
  // { name: 'Отзывы о товарах', link: 'feedback', icon: MsgIcon },
  // { name: 'Сообщения', link: 'messages', icon: MsgEmptyIcon },
  // { name: 'Электронные чеки', link: 'checks', icon: CheckIcon },
  // { name: 'Служба поддержки', link: 'helpdesk', icon: PhoneIcon },
  // { name: 'Стать продавцом', link: 'beseller', icon: PortfelIcon },
];

export default function ProfilePage() {
  const { logout } = useAuth();
  const [contentType, setContentType] = useState<string>('history');
  const { data, isLoading, isError } = useMyProfile();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const logOut = () => {
    logout();
    navigate('/');
    message.success('Вы вышли с аккаунта');
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (isError) {
    return <div>Error loading data</div>;
  }
  const getContentByType = () => {
    switch (contentType) {
      case 'favourites':
        return <FavouriteProducts />;
      case 'shops':
        return <FavouriteShops />;
      case 'edit':
        return <EditProfile userInfo={data} setContentType={setContentType} />;
      // case 'feedback':
      //   return <Feedback token={token} />;
      // case 'returns':
      //   return <Typography.Title level={2}>Мои возвраты</Typography.Title>;
      // case 'messages':
      //   return <Typography.Title level={2}>Сообщения</Typography.Title>;
      // case 'checks':
      //   return <Typography.Title level={2}>Электронные чеки</Typography.Title>;
      // case 'helpdesk':
      //   return <Typography.Title level={2}>Служба поддержки</Typography.Title>;
      // case 'beseller':
      //   return <Typography.Title level={2}>Стать продавцом</Typography.Title>;
      // case 'history':
      default:
        return <History token={token} />;
    }
  };
  return (
    <div className="page-container my-profile__menu-container">
      <div className="my-profile__menu">
        <div className="my-profile__menu__info">
          <div className="my-profile__avatar-container">
            <img src={avatar} alt="avatar" className="main-profile__avatar" />
            <h3 className="my-profile__name">
              {data.fullName === null ? 'Заполните имя' : data.fullName}
              &nbsp;
              {data.lastname === null ? 'Заполните имя' : data.lastname}
            </h3>
          </div>
          <div>
            <div className="main-profile__function-btns">
              <button
                type="button"
                className="my-profile__profile-btn"
                onClick={() => setContentType('edit')}
              >
                Профиль
              </button>
              <button
                type="button"
                className="my-profile__exit-btn"
                onClick={logOut}
              >
                Выйти
              </button>
              <button
                type="button"
                className="my-profile__exit-btn"
                onClick={logOut}
              >
                Удалить аккаунт
              </button>
            </div>
          </div>
        </div>
        <div className="my-profile__menu__list">
          {sidebarItems.map((item) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              key={item.name}
              className="menu-item"
              onClick={() => setContentType(item.link)}
            >
              <div className="menu-item__line">
                <div className="menu-item__icon">
                  <img src={item.icon} alt="icon" />
                </div>
                <div className="menu-item__name">{item.name}</div>
              </div>
              <img
                src={NextArrowIcon}
                alt="NextArrowIcon"
                className="next-arrow"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="my-profile__order-history">{getContentByType()}</div>
    </div>
  );
}
