import { Rate } from 'antd';
import '../styles.scss';
import { HeartIcon } from '../../../icons';
import shopIcon from '../../../icons/svg/shop_samsung.svg';

const items = [
  { name: 'Mon Amie', rating: 5, newProducts: 35, icon: shopIcon },
  { name: 'Mon Amie', rating: 5, newProducts: 43 },
  { name: 'Mon Amie', rating: 4.9, newProducts: 15, icon: shopIcon },
  { name: 'Mon Amie', rating: 4.8, newProducts: 55, icon: shopIcon },
  { name: 'Mon Amie', rating: 2.4, newProducts: 52 },
];

export default function FavouriteShops() {
  return (
    <>
      <h2 className="fav-shops__title">Любимые магазины</h2>
      <div className="fav-shops__container">
        {items.map((item) => (
          <div className="fav-shops__container-row" key={item.name}>
            {item.icon ? (
              <img src={item.icon} alt="store" className="fav-shops__icon" />
            ) : (
              <span className="fav-shops__circle" />
            )}
            <div className="fav-shops__content">
              <div className="fav-shops__text-content">
                <div className="fav-shops__text-content-row">
                  <h4 className="fav-shops__name">
                    {item.name} {item.rating}
                  </h4>
                  <Rate count={1} value={5} />
                </div>
                <div className="fav-shops__count">
                  Новые поступления: {item.newProducts}
                </div>
              </div>
              <img
                src={HeartIcon}
                alt="heart-icon"
                className="fav-shops__heart-icon"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
