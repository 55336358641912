import { useMutation } from 'react-query';
import createUser, { loginUser } from './auth';
import { UserLogin, UserRegister } from '../../types/register-types';

export default function useCreateUser() {
  const { mutate, isSuccess, isError } = useMutation(
    ['createUser'],
    ({
      password,
      smsCode,
      phoneNumber,
      fullName,
      lastName,
      cityId,
    }: UserRegister) =>
      createUser({
        fullName,
        lastName,
        cityId,
        phoneNumber,
        smsCode,
        password,
      })
  );

  return { isSuccess, mutate, isError };
}

export function useLoginUser() {
  const { mutate, isSuccess } = useMutation(
    ['loginUser'],
    ({ password, phoneNumber }: UserLogin) =>
      loginUser({
        phoneNumber,
        password,
      })
  );

  return { isSuccess, mutate };
}
