// import { Modal } from 'antd';
// import React, { useState } from 'react';
// import RegisterModal from '../register-modal';
// import './styles.css';
// import LoginForm from './login-form';
//
// type Props = {
//   isOpen: boolean;
//   onCloseModal: () => void;
// };
//
// export default function LoginModal({ isOpen, onCloseModal }: Props) {
//   const [isLogin, setIsLogin] = useState<boolean>(true);
//
//   const onClose = () => {
//     setIsLogin(true);
//     onCloseModal();
//   };
//
//   const onCreateAccount = () => {
//     setIsLogin(false);
//   };
//
//   const onChangeContent = () => {
//     switch (isLogin) {
//       case true:
//         return (
//           <LoginForm
//             onCreateAccount={onCreateAccount}
//             onCloseModal={onCloseModal}
//           />
//         );
//       case false:
//         return (
//           <div
//             style={{
//               display: 'flex',
//               justifyContent: 'center',
//               textAlign: 'center',
//             }}
//           >
//             <RegisterModal onCloseModal={onCloseModal} />
//           </div>
//         );
//       default:
//         return <div />;
//     }
//   };
//
//   return (
//     <div>
//       <Modal
//         open={isOpen}
//         onCancel={onClose}
//         footer={false}
//         width="407px"
//         className="login-modal"
//       >
//
//         {onChangeContent()}
//       </Modal>
//     </div>
//   );
// }
// LoginModal.tsx
import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import RegisterModal from '../register-modal';
import './styles.css';
import LoginForm from './login-form';

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
};

export default function LoginModal({ isOpen, onCloseModal }: Props) {
  const [isLogin, setIsLogin] = useState<boolean>(true);

  useEffect(() => {
    const { body } = document;
    if (isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }
    return () => {
      body.style.overflow = '';
    };
  }, [isOpen]);

  const onClose = () => {
    setIsLogin(true);
    onCloseModal();
  };

  const onCreateAccount = () => {
    setIsLogin(false);
  };

  const onChangeContent = () => {
    return isLogin ? (
      <LoginForm
        onCreateAccount={onCreateAccount}
        onCloseModal={onCloseModal}
      />
    ) : (
      <div className="register-modal-content">
        <RegisterModal onCloseModal={onCloseModal} />
      </div>
    );
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width="100%"
      className="login-modal"
      bodyStyle={{
        padding: '20px',
        maxHeight: 'calc(100vh - 40px)',
        overflowY: 'auto',
      }}
      style={{ top: 0 }}
      centered={false}
      closable
      getContainer={false}
    >
      {onChangeContent()}
    </Modal>
  );
}
