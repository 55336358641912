import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { Layout, theme } from 'antd';
import { useLocation } from 'react-router-dom';
import useIsMobile from '../../hooks/useMobile';
import NavigationMenu from '../navigation-menu';
import FooterComponent from '../footer';
import MobileNavigationMenu from '../mobile-navigation-menu';
import MobileFooter from '../mobile-footer';

import IsIOSContext from '../../hooks/useIsIOSContext';
import './styles.css';

type LayoutProps = {
  children: React.ReactNode;
};

const { Header, Content, Footer } = Layout;
const { useToken } = theme;

export default function WithLayout({ children }: LayoutProps) {
  const { token } = useToken();
  const isIOS = useContext(IsIOSContext);
  const isMobile = useIsMobile();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [pathname]);

  const headerHeight = isIOS ? 114 : 64;
  const footerHeight = isMobile ? 160 : 0;
  const minHeightOffset = isIOS ? 129 : 64;

  const minHeightFooterOffset = isMobile ? 100 : 0;

  const contentStyles: React.CSSProperties = useMemo(
    () => ({
      position: isMobile ? 'fixed' : 'relative',
      top: `${headerHeight}px`,
      bottom: `${footerHeight}px`,
      marginTop: isMobile ? '' : '32px',
      left: '0',
      right: '0',
      overflowY: isMobile ? 'auto' : 'visible',
      minHeight: `calc(100% - ${minHeightOffset}px - ${minHeightFooterOffset / 2}px)`,
    }),
    [isMobile, isIOS]
  );

  return (
    <Layout className="layout-content" style={{ height: '100%' }}>
      <Header
        className="nav-menu"
        style={{ backgroundColor: token.colorPrimaryBg }}
      >
        {isMobile ? <MobileNavigationMenu IOS={isIOS} /> : <NavigationMenu />}
      </Header>
      <Content
        ref={contentRef}
        style={contentStyles}
        className="content-layout"
      >
        {children}
      </Content>
      <Footer
        style={{
          height: '65px',
          padding: '4px 0 16px 0',
          width: '100%',
          zIndex: 1,
          position: isMobile ? 'fixed' : 'relative',
          backgroundColor: '#fff',
        }}
        className="layout-footer"
      >
        {isMobile ? <MobileFooter /> : <FooterComponent />}
      </Footer>
    </Layout>
  );
}
