import { QueryObserverResult, useQuery } from 'react-query';
import { IViewedProducts } from '../../types/viewed-products';
import getViewedProducts from './viewed-products';

interface HomePageProductsResponse {
  content: IViewedProducts[];
}
export default function useViewedProducts(): {
  data: HomePageProductsResponse | undefined;
  refetch: () => Promise<QueryObserverResult<HomePageProductsResponse, Error>>;
} {
  const { data, refetch } = useQuery<HomePageProductsResponse, Error>(
    ['ViewedProducts'],
    getViewedProducts
  );

  return { data, refetch };
}
