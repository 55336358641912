import { Space, theme, Typography, QRCode } from 'antd';
import './styles.css';
import { NavLink } from 'react-router-dom';
import {
  FacebookFilled,
  InstagramOutlined,
  TwitterOutlined,
} from '@ant-design/icons';
import AppStore from '../../icons/AppStore';

export default function FooterComponent() {
  const { token } = theme.useToken();
  return (
    <div className="footer">
      <div className="footer-body">
        <Space align="center" style={{ padding: '0' }}>
          <div
            style={{
              paddingLeft: '20%',
              paddingRight: '20%',
              width: '1500px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '5%',
              }}
            >
              <Typography.Title
                level={4}
                style={{
                  margin: '0',
                  color: `${token.colorPrimaryBg}`,
                }}
              >
                Aman
              </Typography.Title>
              <div style={{ display: 'flex', gap: '10px' }}>
                <FacebookFilled
                  style={{
                    fontSize: '24px',
                    color: `${token.colorPrimaryBg}`,
                  }}
                />
                <InstagramOutlined
                  style={{
                    fontSize: '24px',
                    color: `${token.colorPrimaryBg}`,
                  }}
                />
                <TwitterOutlined
                  style={{
                    fontSize: '24px',
                    color: `${token.colorPrimaryBg}`,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '5%',
                gap: '10px',
              }}
            >
              <div>
                <Typography.Title level={4}>О компании</Typography.Title>
                <Typography.Title level={5}>О нас</Typography.Title>
                <Typography.Title level={5}>Карьеры</Typography.Title>
                <h5 className="footer__link">
                  <NavLink
                    to="/user-agreement"
                    style={({ isActive }) => ({
                      color: isActive ? '#151296' : 'inherit',
                      border: 'none',
                      background: '#fff',
                    })}
                  >
                    Пользовательское соглашениеs
                  </NavLink>
                </h5>
                <h5 className="footer__link">
                  <NavLink
                    to="/public-contract-offer"
                    style={({ isActive }) => ({
                      color: isActive ? '#151296' : 'inherit',
                      border: 'none',
                      background: '#fff',
                    })}
                  >
                    Публичный договор
                  </NavLink>
                </h5>
              </div>
              <div>
                <Typography.Title level={4}>Продавцам</Typography.Title>
                <Typography.Title level={5}>Бизнес с нами</Typography.Title>
                <Typography.Title level={5}>
                  Аналитика клиентов
                </Typography.Title>
                <Typography.Title level={5}>Блог</Typography.Title>
              </div>
              <div>
                <Typography.Title level={4}>Покупателям</Typography.Title>
                <Typography.Title level={5}>Оплата и доставка</Typography.Title>
                <Typography.Title level={5}>Возврат и обмен</Typography.Title>
                <Typography.Title level={5}>Наши контакты</Typography.Title>
              </div>
              <div
                style={{
                  marginTop: '30px',
                  display: 'flex',
                  gap: '20px',
                }}
              >
                <AppStore />
                <QRCode type="svg" value="https://ant.design/" />
              </div>
            </div>
          </div>
        </Space>
      </div>
      <div
        style={{
          backgroundColor: token.colorPrimaryBg,
          padding: '10px',
          textAlign: 'center',
        }}
      >
        <Typography.Title level={5} style={{ color: 'white' }}>
          Все товарные знаки, размещённые на сайте, являются товарными знаками
          правообладателя и используются на сайте исключительно в информационных
          целях
        </Typography.Title>
      </div>
    </div>
  );
}
