// SelectedItemsContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Order } from '../types/basket';

interface SelectedItemsContextType {
  selectedItems: number[];
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
}

const SelectedItemsContext = createContext<
  SelectedItemsContextType | undefined
>(undefined);

export const useSelectedItems = (): SelectedItemsContextType => {
  const context = useContext(SelectedItemsContext);
  if (!context) {
    throw new Error(
      'useSelectedItems must be used within a SelectedItemsProvider'
    );
  }
  return context;
};

export function SelectedItemsProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: Order[];
}) {
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((itemId) =>
        data.some((item) => item.id === itemId)
      )
    );
  }, [data]);

  // Wrap the value in useMemo to prevent re-renders
  const providerValue = useMemo(
    () => ({ selectedItems, setSelectedItems }),
    [selectedItems, setSelectedItems]
  );

  return (
    <SelectedItemsContext.Provider value={providerValue}>
      {children}
    </SelectedItemsContext.Provider>
  );
}
