import React, { useMemo, useState } from 'react';
import { Button, theme } from 'antd';
import './style.css';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../login-modal';
import { LogoSvgMobile } from '../../icons/Logo';
import { useAuth } from '../../hooks/authContext';
import manLogo from '../../icons/manLogo.svg';

type MobileNavigationMenuProps = {
  IOS: boolean;
};

export default function MobileNavigationMenu({
  IOS,
}: MobileNavigationMenuProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { role } = useAuth();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const contentStyles = useMemo(
    () => ({
      ...(IOS ? { paddingTop: '50px' } : {}),
      backgroundColor: token.colorPrimaryBg,
    }),
    [IOS, token.colorPrimaryBg]
  );

  return (
    <div style={contentStyles} className="mobile-navigation-menu">
      <div className="mobile-navigation-header">
        <div>
          <LogoSvgMobile />
        </div>
        {/* <Button className="nav-btn__menu" onClick={() => navigate('/')}> */}
        {/*  Главная */}
        {/* </Button> */}
        <div>
          {role?.role === 'ROLE_CLIENT' ? (
            <Button
              className="nav-btn__menu"
              onClick={() => navigate('/my-profile')}
            >
              <img src={manLogo} alt={manLogo} className="icons" />
              Профиль
            </Button>
          ) : (
            <Button className="nav-btn__menu" onClick={onOpen}>
              <img src={manLogo} alt={manLogo} />
              Войти
            </Button>
          )}
        </div>
      </div>
      {/* <Input.Search */}
      {/*  className="input-search" */}
      {/*  placeholder="Поиск по названию товара" */}
      {/*  enterButton={ */}
      {/*    <Button */}
      {/*      style={{ */}
      {/*        background: '#ffffff', */}
      {/*        height: '48px', */}
      {/*        borderLeft: 'none', */}
      {/*        fontSize: '20px', */}
      {/*        borderTopRightRadius: '14px', */}
      {/*        borderBottomRightRadius: '14px', */}
      {/*      }} */}
      {/*    > */}
      {/*      <svg */}
      {/*        xmlns="http://www.w3.org/2000/svg" */}
      {/*        width="24" */}
      {/*        height="24" */}
      {/*        viewBox="0 0 24 24" */}
      {/*        fill="none" */}
      {/*      > */}
      {/*        <path */}
      {/*          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" */}
      {/*          stroke="#100E71" */}
      {/*          strokeWidth="2" */}
      {/*          strokeLinecap="round" */}
      {/*          strokeLinejoin="round" */}
      {/*        /> */}
      {/*        <path */}
      {/*          d="M20.9999 21.0004L16.6499 16.6504" */}
      {/*          stroke="#100E71" */}
      {/*          strokeWidth="2" */}
      {/*          strokeLinecap="round" */}
      {/*          strokeLinejoin="round" */}
      {/*        /> */}
      {/*      </svg> */}
      {/*    </Button> */}
      {/*  } */}
      {/* /> */}
      <LoginModal isOpen={isOpen} onCloseModal={onClose} />
    </div>
  );
}
