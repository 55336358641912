// colors.js

export default function getColors() {
  return [
    { id: 1, colorRU: 'Красный', colorENGBack: 'RED' },
    { id: 2, colorRU: 'Черный', colorENGBack: 'BLACK' },
    { id: 3, colorRU: 'Розовый', colorENGBack: 'PINK' },
    { id: 4, colorRU: 'Оранжевый', colorENGBack: 'ORANGE' },
    { id: 5, colorRU: 'Белый', colorENGBack: 'WHITE' },
    { id: 6, colorRU: 'Зелёный', colorENGBack: 'GREEN' },
    { id: 7, colorRU: 'Синий', colorENGBack: 'BLUE' },
    { id: 8, colorRU: 'Жёлтый', colorENGBack: 'YELLOW' },
    { id: 9, colorRU: 'Коричневый', colorENGBack: 'BROWN' },
    { id: 10, colorRU: 'Фиолетовый', colorENGBack: 'PURPLE' },
    { id: 11, colorRU: 'Серый', colorENGBack: 'GRAY' },
    { id: 12, colorRU: 'Бежевый', colorENGBack: 'BEIGE' },
    { id: 13, colorRU: 'Голубой', colorENGBack: 'SKYBLUE' },
  ];
}
