import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import WithLayout from './components/layout';
import MainPage from './pages/main-page';
import CategoryPage from './pages/category-page';
import CartPage from './pages/cart-page';
import FavouriteProductsPage from './pages/favourite-products-page';
import ProductDetailPage from './pages/product-detail-page';
import ProfilePage from './pages/profile-page';
import RetailerPage from './pages/retailer-page';
import NotFoundPage from './pages/not-found-error-page';
import UserAgreement from './pages/documents/user-agreements';
import PublicContractOffer from './pages/documents/public-contract-offer';
import SubCategoryProductsPage from './pages/sub-category-products-page';

import IsIOSContext from './hooks/useIsIOSContext';
import usePreventZoom from './hooks/usePreventZoom';
import ScrollToTop from './hooks/scrool-top';
import CartAdd from './pages/cart-add';

import './App.css';

const queryClient = new QueryClient();

export default function App() {
  usePreventZoom();
  const storedCountry = localStorage.getItem('country');
  const [currentCountry] = useState<string | null>(storedCountry);

  const queryParams = new URLSearchParams(window.location.search);
  const isIOSParam = queryParams.get('isIOS');
  const storedIsIOS = localStorage.getItem('isIOS');

  const isIOSValue =
    isIOSParam !== null ? isIOSParam === 'true' : storedIsIOS === 'true';
  if (isIOSParam !== null) {
    localStorage.setItem('isIOS', isIOSValue.toString());
  }

  const [isIOS] = useState<boolean>(isIOSValue);

  const primaryColor = currentCountry === 'KZ' ? '#100E71' : '#100E71';
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: primaryColor,
            },
            components: {
              Radio: {
                buttonSolidCheckedBg: primaryColor,
                buttonSolidCheckedHoverBg: primaryColor,
                radioSize: 25,
                dotSize: 13,
              },
              Button: {
                colorPrimaryBg: primaryColor,
              },
            },
          }}
        >
          <IsIOSContext.Provider value={isIOS}>
            <BrowserRouter>
              <ScrollToTop />
              <WithLayout>
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/categories" element={<CategoryPage />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route
                    path="/favourites"
                    element={<FavouriteProductsPage />}
                  />
                  <Route path="/cart-add" element={<CartAdd />} />
                  <Route
                    path="/category/subcategory/products/:categoryId"
                    element={<SubCategoryProductsPage />}
                  />
                  <Route
                    path="/category/subcategory/product/:id"
                    element={<ProductDetailPage />}
                  />
                  <Route path="/retailer-page" element={<RetailerPage />} />
                  <Route path="/my-profile" element={<ProfilePage />} />
                  <Route path="/user-agreement" element={<UserAgreement />} />
                  <Route
                    path="/public-contract-offer"
                    element={<PublicContractOffer />}
                  />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </WithLayout>
            </BrowserRouter>
          </IsIOSContext.Provider>
        </ConfigProvider>
      </div>
    </QueryClientProvider>
  );
}
