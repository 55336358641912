import { Col, Input, Radio, Space, Switch, theme, Typography } from 'antd';
import CountrySwitch from '../../../components/country-switch';
import FavouriteHeartSvg from '../../../icons/FavouriteHeart';

export default function NonEmptyFavouriteProducts() {
  const { token } = theme.useToken();
  return (
    <div style={{ display: 'flex', gap: '4rem' }}>
      <Col>
        <div>
          <div style={{ textAlign: 'center', marginTop: '45px' }}>
            <FavouriteHeartSvg width="110" height="110" />
            <Typography.Title level={4} style={{ margin: '0' }}>
              Избранное
            </Typography.Title>
          </div>
        </div>
        <div>
          <Typography.Title level={5}>Сортировать по:</Typography.Title>
        </div>
        <div>
          <Typography.Title level={5}>Цена</Typography.Title>
          <div style={{ display: 'flex', gap: '5px' }}>
            <Input placeholder="от" />
            -
            <Input placeholder="до" />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              В наличии
            </Typography.Title>
            <Switch
              style={{
                backgroundColor: token.colorPrimaryBg,
              }}
            />
          </div>
        </div>
        <div>
          <Typography.Title level={5}>Пол</Typography.Title>
          <Space direction="vertical">
            <Radio>Для мужчин</Radio>
            <Radio>Для женщин</Radio>
            <Radio>Унисекс</Radio>
          </Space>
        </div>
        <div>
          <Typography.Title level={5}>Бренд</Typography.Title>
          <Space direction="vertical">
            <Radio>Adidas</Radio>
            <Radio>Nike</Radio>
            <Radio>Puma</Radio>
          </Space>
        </div>
        <div>
          <Typography.Title level={5}>Категории</Typography.Title>
          <Space direction="vertical">
            <Radio>Одежда</Radio>
            <Radio>Смартфоны и гаджеты</Radio>
            <Radio>Косметика</Radio>
          </Space>
        </div>
      </Col>
      <Col flex="auto">
        <CountrySwitch />
        <div className="main-page__popular-products-list" />
      </Col>
    </div>
  );
}
