import { useRef, useState } from 'react';
import { Carousel, Radio, RadioChangeEvent, Select, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { CarouselRef } from 'antd/es/carousel';
import { color } from 'framer-motion';
import Spinner from '../../components/UI/Spinner';

import './style.css';
import SubCategoryProducts from './subcategory-products';
import SubcategoryCard from './sub-category-card';
import useSubCategory, {
  useOneCategory,
} from '../../api/sub-category/sub-category-hooks';
import { Category } from '../../types/categoties';
import useCategoriesLevelOne from '../../api/categories/categories-level-one-hooks';
import getColors from '../../hooks/color';
import useProductCategory from '../../api/products/products-hooks';
import CustomModal from './custom-modal/custom-modal';
import PaginationElem from '../../components/UI/pagination-elem/pagination-elem';
import SelectorArrows from '../../icons/selector-arrows';
import MobileCategories from './mobile-modal-categories/mobile-modal-categories';

interface SubCategory {
  categoryName: string;
  id: number;
}

export default function SubCategoryProductsPage() {
  const colors = getColors();
  const { categoryId } = useParams<{ categoryId: string }>();
  const { data: allCategories, isLoading, error } = useCategoriesLevelOne(0);
  const carouselRef = useRef<CarouselRef>(null);
  const [showAllColors, setShowAllColors] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [isModalSortOpen, setIsModalSortOpen] = useState(false);
  const [isModalFilterOpen, setIsModalFilterOpen] = useState(false);
  const [paginataionPage, setPaginataionPage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);
  const [chosenSubcategory, setChosenSubcategory] = useState<number>(
    Number(categoryId)
  );
  const [parentCategory, setParentCategory] = useState<number>(
    Number(categoryId)
  );

  const { data: parentCategoriesData, isLoading: isSubCategoriesLoading } =
    useSubCategory({
      id: parentCategory,
      page: 0,
      size: 10,
    });

  const { data: oneCategories } = useOneCategory(parentCategory);
  const initialVisibleCount = 3;

  const categories = parentCategoriesData?.content || [];

  const visibleCategories = showAll
    ? categories
    : categories.slice(0, initialVisibleCount);
  const hasMore = categories.length > initialVisibleCount;

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const initialVisibleCountColors = 10;

  const visibleColors = showAllColors
    ? colors
    : colors.slice(0, initialVisibleCountColors);
  const hasMoreColors = colors.length > initialVisibleCountColors;

  const toggleShowAllColor = () => {
    setShowAllColors((prevShowAll) => !prevShowAll);
  };

  const activeSubcategoryHandler = (subcategoryId: number) => {
    setChosenSubcategory(subcategoryId);
  };

  const [priceFrom, setPriceFrom] = useState<number | undefined>(undefined);
  const [priceTo, setPriceTo] = useState<number | undefined>(undefined);
  const [productColor, setProductColor] = useState<
    { color: string; colorId: number } | undefined
  >(undefined);
  const [productName, setProductName] = useState<string | undefined>(undefined);

  const handleProductColor = (colorENG: string, colorId: number) => {
    if (productColor?.color === colorENG) {
      setProductColor(undefined);
    } else {
      setProductColor({ color: colorENG, colorId });
    }
  };

  const activeParentCategoryHandler = (parentCategoryId: number) => {
    setParentCategory(parentCategoryId);
    setChosenSubcategory(parentCategoryId);
    setPaginataionPage(0);
    setPriceFrom(undefined);
    setPriceTo(undefined);
    setProductColor(undefined);
    setProductName(undefined);
  };

  const {
    data: CategoryProducts,
    isLoading: LoadingProd,
    error: ErrorProd,
    refetch,
  } = useProductCategory(
    chosenSubcategory,
    'kz',
    paginataionPage,
    16,
    priceFrom,
    priceTo,
    productColor?.color,
    productName
  );
  const productFilter = CategoryProducts?.content;
  const totalPages: number = CategoryProducts?.totalPages;

  const minPrice: number = productFilter
    ? Math.min(...productFilter.map((product: any) => product.pricePerPiece))
    : 0;
  const maxPrice = productFilter
    ? Math.max(...productFilter.map((product: any) => product.pricePerPiece))
    : 5000;
  const [value, setValue] = useState<number[]>([minPrice, maxPrice]);
  const handleSliderChange = (values: [number, number]) => {
    const [minValue, maxValue] = values;
    setPriceFrom(minValue);
    setPriceTo(maxValue);
    setValue(values);
  };

  const options = ['Новинки', 'Дешевле', 'Дороже'];
  const [selectedOption, setSelectedOption] = useState<string>('Новинки');

  const handlerCloseModal = () => {
    setIsModalFilterOpen(false);
    setIsModalSortOpen(false);
    document.querySelector('main')!.classList.remove('body-hidden');
  };

  const handlerOpenModalFilter = () => {
    setModalTitle('Фильтр');
    setIsModalFilterOpen(true);
    document.querySelector('main')!.classList.add('body-hidden');
  };

  const handlerOpenModalSort = () => {
    setModalTitle('Сортировка');
    setIsModalSortOpen(true);
    document.querySelector('main')!.classList.add('body-hidden');
  };

  let productFilterSorted = productFilter || [];
  if (selectedOption === 'Дешевле') {
    productFilterSorted = productFilterSorted.sort(
      (a: any, b: any) => a.pricePerPiece - b.pricePerPiece
    );
  } else if (selectedOption === 'Дороже') {
    productFilterSorted = productFilterSorted.sort(
      (a: any, b: any) => b.pricePerPiece - a.pricePerPiece
    );
  } else if (selectedOption === 'Новинки') {
    productFilterSorted = productFilterSorted.sort(
      (a: any, b: any) => a.createDate - b.createDate
    );
  }
  const handleSelectMobileChange = (e: RadioChangeEvent) => {
    setSelectedOption(e.target.value);
  };

  const handleSelectChange = (selected: string) => {
    setSelectedOption(selected);
  };

  const handlePageChange = (page: number) => {
    setPaginataionPage(page - 1);
  };

  if (error) {
    return <div>Ошибка</div>;
  }

  if (isLoading || isSubCategoriesLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="sub-category-products__outer-wrap"
      style={{
        paddingBottom: isModalSortOpen || isModalFilterOpen ? '0' : '20%',
      }}
    >
      <div className="sub-category-products__inner-wrap">
        <div className="sub-category-products__page">
          {allCategories && allCategories.content.length > 0 ? (
            <Carousel
              ref={carouselRef}
              dots={false}
              arrows={!isMobile}
              draggable={isMobile}
              slidesToShow={isMobile ? 4 : 5}
              infinite
              className="subcategories-carousel"
            >
              {allCategories.content.map((category: Category) => (
                <div key={category.id}>
                  <SubcategoryCard
                    name={category.categoryName}
                    image={category.imageUrl}
                    onClickFunc={() => activeParentCategoryHandler(category.id)}
                    givenClassName={
                      parentCategory === category.id
                        ? 'active subcategory-card'
                        : 'subcategory-card'
                    }
                  />
                </div>
              ))}
            </Carousel>
          ) : (
            <p>Простите, подкатегорий нет</p>
          )}
          {/* <div className="subcategory-div__selector"> */}
          {/*  <button */}
          {/*    type="button" */}
          {/*    className="subcategory-mobile__filter" */}
          {/*    onClick={handlerOpenModalFilter} */}
          {/*  > */}
          {/*    Фильтр */}
          {/*  </button> */}
          {/*  <Select */}
          {/*    className="select-div" */}
          {/*    defaultValue="Новинки" */}
          {/*    onChange={handleSelectChange} */}
          {/*    options={options.map((option) => ({ */}
          {/*      value: option, */}
          {/*      label: option, */}
          {/*    }))} */}
          {/*  /> */}
          {/*  <button */}
          {/*    type="button" */}
          {/*    className="select-div__mobile" */}
          {/*    onClick={handlerOpenModalSort} */}
          {/*  > */}
          {/*    {selectedOption} <SelectorArrows /> */}
          {/*  </button> */}
          {/* </div> */}
          <div className="sub-category-main">
            <div className="filter-div">
              <div className="filter">
                <Typography.Title
                  className="parent-info-name"
                  level={5}
                  style={{
                    textAlign: 'center',
                    marginBottom: '50px',
                  }}
                >
                  {oneCategories?.categoryName}
                </Typography.Title>
              </div>
              <div className="btn-div-style">
                {visibleCategories.map((category: SubCategory) => (
                  <button
                    className="btn-styles"
                    type="button"
                    key={category.id}
                    style={{
                      cursor: 'pointer',
                      color:
                        chosenSubcategory === category.id ? 'red' : 'black',
                    }}
                    onClick={() => activeSubcategoryHandler(category.id)}
                  >
                    {category.categoryName}
                  </button>
                ))}
                {hasMore && (
                  <button
                    type="button"
                    className="toggle-button-color"
                    onClick={toggleShowAll}
                  >
                    {showAll ? 'Скрыть' : 'Посмотреть все'}
                  </button>
                )}
              </div>
            </div>
            <div className="sub-products-list">
              {chosenSubcategory && (
                <SubCategoryProducts
                  refetch={refetch}
                  isLoading={LoadingProd}
                  error={ErrorProd}
                  CategoryProducts={productFilterSorted}
                />
              )}
              <div
                className="products-pagination"
                style={{ display: totalPages <= 1 ? 'none' : 'flex' }}
              >
                <PaginationElem
                  currentPage={paginataionPage + 1}
                  onChange={handlePageChange}
                  total={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CustomModal */}
      {/*  onClose={handlerCloseModal} */}
      {/*  isOpen={isModalSortOpen || isModalFilterOpen} */}
      {/*  title={modalTitle} */}
      {/* > */}
      {/*  <Radio.Group value={selectedOption} onChange={handleSelectMobileChange}> */}
      {/*    {isModalSortOpen ? ( */}
      {/*      options.map((option) => ( */}
      {/*        <Radio */}
      {/*          value={option} */}
      {/*          key={option} */}
      {/*          style={{ */}
      {/*            display: 'flex', */}
      {/*            flexDirection: 'row-reverse', */}
      {/*            width: '100%', */}
      {/*            justifyContent: 'space-between', */}
      {/*            borderBottom: '1px solid #DDDEDF', */}
      {/*            padding: '16px 0', */}
      {/*            fontSize: '16px', */}
      {/*          }} */}
      {/*        > */}
      {/*          {option} */}
      {/*        </Radio> */}
      {/*      )) */}
      {/*    ) : ( */}
      {/*      <MobileCategories */}
      {/*        categories={categories} */}
      {/*        active={categories[chosenSubcategory]} */}
      {/*        setActive={setChosenSubcategory} */}
      {/*      /> */}
      {/*    )} */}
      {/*  </Radio.Group> */}
      {/* </CustomModal> */}
    </div>
  );
}
