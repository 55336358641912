import { api } from '../api';

interface CatalogParams {
  id: number;
  page: number;
  size?: number;
}

export default async function SubCategory({ id, page, size }: CatalogParams) {
  const { data } = await api.get(`/aman/categories/catalog`, {
    params: {
      categoryId: id,
      page,
      size,
      sort: 'abs',
    },
  });

  return data;
}
export async function OneCategory(id: number | undefined) {
  const { data } = await api.get(`/aman/categories/${id}`);
  return data;
}
