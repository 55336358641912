import { Button, Input, message, theme, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { UserInfoContext } from '../index';
import useCreateUser from '../../../api/auth/auth-hooks';

type PasswordFormProps = {
  setIsNext: React.Dispatch<React.SetStateAction<string>>;
};
message.config({
  top: 100,
});
export default function SmsCodeForm({ setIsNext }: PasswordFormProps) {
  const [code, setIsCode] = useState<string>('');
  const context = useContext(UserInfoContext);
  const { mutate } = useCreateUser();
  const { token } = theme.useToken();

  const onSubmit = () => {
    context.setUser({
      fullName: context.user.fullName,
      password: context.user.password,
      phoneNumber: context.user.phoneNumber,
      smsCode: code,
    });
    mutate(
      {
        fullName: '',
        cityId: '',
        lastName: '',
        password: '',
        smsCode: code,
        phoneNumber: context.user.phoneNumber,
      },
      {
        onSuccess: () => {
          message.success('Смс кодверный');
          setIsNext('password');
        },
        onError: () => {
          message.error('Ошибка при отправке смс кода');
        },
      }
    );
  };

  const onChangeCode = (e: any) => {
    setIsCode(e.target.value);
  };

  return (
    <div>
      <div>
        <Typography.Title level={3}>Введите СМС-код</Typography.Title>
        <div>
          <span>
            Мы отправили код на {context.user?.email === '' ? 'номер' : 'email'}
          </span>
        </div>
        <span>
          {context.user?.email === ''
            ? context.user?.phoneNumber
            : context.user?.email}
        </span>
        <Input
          className="register-form__input"
          placeholder="КОД"
          onChange={onChangeCode}
        />
      </div>

      <Button
        className="register-form__submit-btn"
        onClick={onSubmit}
        style={{
          backgroundColor: token.colorPrimaryBg,
          border: token.colorPrimaryBg,
        }}
      >
        Продолжить
      </Button>
    </div>
  );
}
