import './style.css';
import { Button, theme } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { useToken } from 'antd/es/theme/internal';
import addToFavorite, {
  deleteFromFavorite,
} from '../../../api/favorite/favorite';
import addBasket from '../../../api/basket/add-basket';
import { Product, ProductCategory } from '../../../types/product-detail';
import { useAuth } from '../../../hooks/authContext';
import AuthModal from '../../../components/UI/Modal';

type TSubCategoryProducts = {
  refetch: () => void;
  isLoading: boolean;
  error: unknown;
  CategoryProducts: ProductCategory[];
};

export default function SubCategoryProducts({
  refetch,
  isLoading,
  error,
  CategoryProducts,
}: TSubCategoryProducts) {
  const { role } = useAuth();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { token } = theme.useToken();

  const mutationBasket = useMutation((id: number) => addBasket(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const mutationAddToFavorite = useMutation((id: number) => addToFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const deleteFromFavo = useMutation((id: number) => deleteFromFavorite(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const addToBasketHandler = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationBasket.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleUpdate = (id: number) => {
    if (role?.role === 'ROLE_CLIENT') {
      mutationAddToFavorite.mutateAsync(id);
    } else {
      setIsModalVisible(true);
    }
  };

  const handleDelete = (id: number) => {
    deleteFromFavo.mutateAsync(id);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleCardClick = (id: number) => {
    navigate(`/category/subcategory/product/${id}`);
  };
  if (isLoading) {
    return <div>Загрузка продуктов...</div>;
  }

  if (error) {
    return <div>Ошибка при загрузке продуктов: Ошибка</div>;
  }

  return (
    <div className="sub-categories-products">
      <AuthModal visible={isModalVisible} onClose={handleCloseModal} />
      <div className="subcategory-products-list">
        {CategoryProducts
          ? CategoryProducts?.map((categoryProduct: any) => (
              <div
                onClick={() => handleCardClick(categoryProduct.id)}
                key={categoryProduct.id}
                className="subc-product-card"
                role="button"
                tabIndex={0}
                onKeyDown={(e) =>
                  e.key === 'Enter' && handleCardClick(categoryProduct.id)
                }
              >
                {categoryProduct.favourites === true ? (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(categoryProduct.id);
                    }}
                    className="favorite-button product-in-favories"
                    type="button"
                    aria-label="Удалить из избранного"
                  >
                    <HeartFilled />
                  </button>
                ) : (
                  <button
                    style={{ color: '#201F1F' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdate(categoryProduct.id);
                    }}
                    className="favorite-button"
                    type="button"
                    aria-label="Добавить в избранное"
                  >
                    <HeartOutlined />
                  </button>
                )}
                <div className="sub-product-img-block">
                  <img
                    src={categoryProduct.photoUrl[0]}
                    className="sub-product-image"
                    alt={categoryProduct.name || 'Изображение продукта'}
                  />
                </div>
                <div className="product-info-block">
                  <div className="sub-product-title">
                    {categoryProduct.name}
                  </div>
                  <div className="sub-product-seller">Технодом</div>
                  <div className="sub-product-price">
                    {categoryProduct.pricePerPiece} ₸
                  </div>
                </div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    addToBasketHandler(categoryProduct.id);
                  }}
                  className="sub-product-basket-btn"
                >
                  В корзину
                </Button>
              </div>
            ))
          : ''}
      </div>
    </div>
  );
}
