import React, { createContext, useMemo, useState } from 'react';
import { Space } from 'antd';
import PasswordForm from './password-form';
import SmsCodeForm from './sms-code-form';
import InitalForm from './form';
import './styles.css';
import { UserRegister } from '../../types/register-types';
import CityForm from './cityId';

export const UserInfoContext = createContext<any>({} as any);
type Props = {
  onCloseModal: () => void;
};
export default function RegisterModal({ onCloseModal }: Props) {
  const [isNextStep, setIsNextStep] = useState<string>('initial');
  const [user, setUser] = useState<UserRegister>();

  const onChangeContent = () => {
    switch (isNextStep) {
      case 'initial':
        return <InitalForm setIsNext={setIsNextStep} />;
      case 'smsCode':
        return <SmsCodeForm setIsNext={setIsNextStep} />;
      case 'password':
        return <PasswordForm setIsNext={setIsNextStep} />;
      case 'city':
        return (
          <CityForm setIsNext={setIsNextStep} onCloseModal={onCloseModal} />
        );
      default:
        return <div />;
    }
  };

  return (
    <UserInfoContext.Provider
      value={useMemo(() => ({ user, setUser }), [user, setUser])}
    >
      <Space align="center">{onChangeContent()}</Space>
    </UserInfoContext.Provider>
  );
}
