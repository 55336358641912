import { useMutation, useQuery, useQueryClient } from 'react-query';
import { message } from 'antd';
import MyProfile, { EditProfile } from './my-profile';

export default function useMyProfile() {
  const { data, isError, isLoading } = useQuery(['myProfile'], MyProfile);
  return { data, isError, isLoading };
}

message.config({
  top: 100,
});

export function useEditProfile() {
  const queryClient = useQueryClient();

  const mutate = useMutation(
    ({
      fullName,
      lastName,
      email,
      phone,
    }: {
      fullName: string;
      lastName: string;
      email: string;
      phone: string;
    }) => EditProfile(fullName, lastName, email, phone),
    {
      onSuccess: () => {
        message.success('Профиль успешно обновлен!');
        queryClient.invalidateQueries('userProfile');
      },
      onError: () => {
        message.error('Возникла непредвиденная ошибка:c');
      },
    }
  );

  return mutate;
}
