import React from 'react';

interface HomeIconsProps {
  svgClassName?: string;
}

export default function HomeIcons({ svgClassName }: HomeIconsProps) {
  return (
    <svg
      className={svgClassName}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 30"
    >
      <path d="M17.6458 22.1458V16.8305C17.6458 16.6394 17.5885 16.4865 17.4738 16.3719C17.3591 16.2572 17.2063 16.1999 17.0152 16.1999H13.9848C13.7937 16.1999 13.6381 16.2572 13.518 16.3719C13.4034 16.4865 13.346 16.6394 13.346 16.8305V22.1458H17.6458ZM8.15356 21.8674V15.6266L15.0004 9.88539C15.3335 9.60147 15.6693 9.60147 16.0078 9.88539L22.8546 15.6266V21.8674C22.8546 22.457 22.6799 22.9184 22.3305 23.2515C21.981 23.5845 21.506 23.7511 20.9054 23.7511H10.1028C9.49672 23.7511 9.01897 23.5845 8.66953 23.2515C8.32555 22.9184 8.15356 22.457 8.15356 21.8674ZM5.5 14.5046C5.5 14.2534 5.60647 14.0377 5.81941 13.8575L14.3534 6.69128C14.7083 6.39644 15.0932 6.24902 15.5082 6.24902C15.9232 6.24902 16.3054 6.39644 16.6548 6.69128L25.1806 13.8575C25.3935 14.0323 25.5 14.2561 25.5 14.5291C25.5 14.7639 25.4181 14.9495 25.2543 15.086C25.096 15.2225 24.8994 15.2908 24.6646 15.2908C24.539 15.2908 24.4189 15.2608 24.3043 15.2007C24.1896 15.1406 24.0831 15.0724 23.9848 14.996L15.8604 8.17368C15.7457 8.0754 15.6283 8.02626 15.5082 8.02626C15.3881 8.02626 15.2707 8.0754 15.156 8.17368L7.02334 14.996C6.9196 15.0724 6.8104 15.1406 6.69574 15.2007C6.58654 15.2608 6.46915 15.2908 6.34357 15.2908C6.08149 15.2908 5.87401 15.2144 5.72113 15.0615C5.57371 14.9031 5.5 14.7175 5.5 14.5046ZM20.5614 10.5324V8.52585C20.5614 8.34567 20.616 8.20098 20.7252 8.09178C20.8399 7.97712 20.9873 7.91979 21.1675 7.91979H22.2731C22.4588 7.91979 22.6062 7.97712 22.7154 8.09178C22.8246 8.20098 22.8792 8.34567 22.8792 8.52585V12.4816L20.5614 10.5324Z" />
    </svg>
  );
}
HomeIcons.defaultProps = {
  svgClassName: '',
};
