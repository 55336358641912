import React from 'react';
import CategoryCardCategories from '../../components/category-card-categories/category-card-categories';
import './styles.css';
import useCategoriesLevelOne from '../../api/categories/categories-level-one-hooks';

export default function CategoryPage() {
  const { data: products, isLoading, error } = useCategoriesLevelOne(0);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div className="category-page__outer-wrap">
      <div className="category-page__inner-wrap">
        <div className="category-page__country-switch">
          {/* <CountrySwitch /> */}
        </div>
        <div className="category-page__category-list">
          {products.content.map((product: any) => (
            <CategoryCardCategories
              key={product.id}
              category={product}
              name={product.categoryName}
            />
          ))}
        </div>
        {/* <Pagination */}
        {/*  current={current} */}
        {/*  onChange={onChange} */}
        {/*  total={5} */}
        {/*  style={ */}
        {/*    window.innerWidth <= 768 */}
        {/*      ? { display: 'none' } */}
        {/*      : { display: 'block', paddingLeft: '60px' } */}
        {/*  } */}
        {/* /> */}
      </div>
    </div>
  );
}
