import './style.css';
import { Typography } from 'antd';

interface SubCategoryType {
  name: string;
  image: string | null | undefined;
  onClickFunc: VoidFunction;
  givenClassName: string;
}

export default function SubcategoryCard({
  name,
  image,
  onClickFunc,
  givenClassName,
}: SubCategoryType) {
  const titleStyle = {
    margin: '0',
    fontSize: window.innerWidth <= 768 ? '7px' : '24px',
    width: window.innerWidth <= 768 ? '70%' : '90%',
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={givenClassName}
      onClick={onClickFunc}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onClickFunc();
        }
      }}
    >
      <div className="category-card-fill">
        <div className="category-title">
          <Typography.Title
            style={titleStyle}
            // onClick={() => navigate("/category")}
          >
            {name || 'No Category Name'}
          </Typography.Title>
        </div>

        <img
          alt={name}
          className="category-image"
          src={
            image ||
            'https://png.pngtree.com/png-clipart/20230506/original/pngtree-smiling-dogs-with-happy-expressions-png-image_9144389.png'
          }
        />
      </div>
    </div>
  );
}
