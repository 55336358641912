import React from 'react';
import NonEmptyCart from './non-empty-cart';
import EmptyCart from './empty-cart';
import './styles.css';
import useBasketItems from '../../api/basket/basket-hooks';
import { SelectedItemsProvider } from '../../hooks/selectedItems';

export default function CartPage() {
  const { data, refetch } = useBasketItems();
  if (!data || data.length === 0) {
    return <EmptyCart />;
  }
  return (
    <div className="page-container">
      <SelectedItemsProvider data={data}>
        <NonEmptyCart data={data} refetch={refetch} />
      </SelectedItemsProvider>
    </div>
  );
}
