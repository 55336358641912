import { useEffect, useState } from 'react';

import './styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import getUserRole from '../../hooks/auth';
import AuthModal from '../UI/Modal';
import { useAuth } from '../../hooks/authContext';
import HomeIcons from '../../icons/footer/home-icons';
import CategoriesIcons from '../../icons/footer/categories-icons';
import CartIcons from '../../icons/footer/cart-icons';
import ProfileIcons from '../../icons/footer/profile-icons';

export default function MobileFooter() {
  const { role, setRole } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogin = () => {
    const userRole = getUserRole();
    setRole(userRole);
  };
  const handleCartClick = () => {
    if (role?.role === 'ROLE_CLIENT') {
      navigate('/cart');
    } else {
      setIsModalVisible(true);
    }
  };
  const handleProfileClick = () => {
    if (role?.role === 'ROLE_CLIENT') {
      navigate('/my-profile');
    } else {
      setIsModalVisible(true);
    }
  };
  const handleMainClick = () => {
    navigate('/');
  };
  const handleCategoriesClick = () => {
    navigate('/categories');
  };
  // const handleFavoritesClick = () => {
  //   if (role?.role === 'ROLE_CLIENT') {
  //     navigate('/favourites');
  //   } else {
  //     setIsModalVisible(true);
  //   }
  // };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    handleLogin();
  }, []);
  const isActive = (path: string) => {
    return location.pathname === path;
  };
  return (
    <div className="mobile-footer">
      <div
        style={{
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-evenly',
          // padding: '6px 6.5px 30px 6.5px',
          zIndex: '20',
          height: '55px',
        }}
      >
        <button
          aria-label="Главная"
          type="button"
          className={`footer-btn ${isActive('/') ? 'active' : ''}`}
          onClick={handleMainClick}
          style={{
            width: '74px',
            height: '42px',
            padding: '0',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexDirection: 'column',
            border: '0',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              fontSize: '30px',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HomeIcons svgClassName="icons" />
          </div>
          Главная
        </button>
        <button
          aria-label="Категории"
          type="button"
          className={`footer-btn ${isActive('/categories') ? 'active' : ''}`}
          onClick={handleCategoriesClick}
          style={{
            width: '74px',
            height: '42px',
            padding: '0',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexDirection: 'column',
            border: '0',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              fontSize: '30px',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CategoriesIcons svgClassName="icons" />
          </div>
          Категории
        </button>
        {/* <button */}
        {/* className={`footer-btn ${isActive('/') ? 'active' : ''}`} */}
        {/* onClick={handleMainClick} */}
        {/* style= */}
        {/* {{ */}
        {/*  width: '74px', */}
        {/*  height: '42px', */}
        {/*  padding: '0', */}
        {/*  fontSize: '10px', */}
        {/*  display: 'flex', */}
        {/*  alignItems: 'center', */}
        {/*  justifyContent: 'start', */}
        {/*  flexDirection: 'column', */}
        {/* }} */}
        {/* > */}
        {/*  <div */}
        {/* style= */}
        {/* {{ */}
        {/*  fontSize: '30px', */}
        {/*  width: '30px', */}
        {/*  height: '30px', */}
        {/*  display: 'flex', */}
        {/*  justifyContent: 'center', */}
        {/*  alignItems: 'center', */}
        {/* }} */}
        {/*  > */}
        {/*    <FavoriteIcons svgClassName="icons" /> */}
        {/*  </div> */}
        {/*  Избранное */}
        {/* </button> */}
        <button
          aria-label="Корзина"
          type="button"
          className={`footer-btn ${isActive('/cart') ? 'active' : ''}`}
          onClick={handleCartClick}
          style={{
            width: '74px',
            height: '42px',
            padding: '0',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexDirection: 'column',
            border: '0',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              fontSize: '30px',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CartIcons svgClassName="icons" />
          </div>
          Корзина
        </button>
        <button
          aria-label="Мой профиль"
          type="button"
          className={`footer-btn ${isActive('/my-profile') ? 'active' : ''}`}
          onClick={handleProfileClick}
          style={{
            width: '74px',
            height: '42px',
            padding: '0',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            flexDirection: 'column',
            border: '0',
            backgroundColor: '#fff',
          }}
        >
          <div
            style={{
              fontSize: '30px',
              width: '30px',
              height: '30px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProfileIcons svgClassName="icons" />
          </div>
          Мой профиль
        </button>
      </div>
      <AuthModal visible={isModalVisible} onClose={handleCloseModal} />
    </div>
  );
}
