import { jwtDecode } from 'jwt-decode';
import { Role } from '../types/role';

function getUserRole(): Role | null {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (token) {
    try {
      const decodedToken: Role = jwtDecode<Role>(token);
      return decodedToken;
    } catch (error) {
      return null;
    }
  }
  return null;
}

export default getUserRole;
