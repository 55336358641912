import { api } from '../api';
import { IOrders } from '../../pages/profile-page/history';

interface HistoryOrders {
  content: IOrders[];
}

export default async function getHistoryOrders(
  page: number,
  size: number
): Promise<HistoryOrders> {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const { data } = await api.get(`/profile/orders`, {
    params: {
      page,
      size,
      // sort: 'string',
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
