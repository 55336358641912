import { useQuery } from 'react-query';
import getCities, { getFilials } from './common';

export default function useCities() {
  const { data } = useQuery(['cities'], getCities);

  return { data };
}

export function useFilias(cityId: string) {
  const { data, refetch } = useQuery(
    ['filials', cityId],
    () => getFilials(cityId),
    {
      enabled: !!cityId,
    }
  );
  return { data, refetch };
}
