import { LoadingOutlined } from '@ant-design/icons';
import { Flex, Spin } from 'antd';

function Spinner() {
  return (
    <Flex justify="center" align="center" gap="middle">
      <Spin
        indicator={
          <LoadingOutlined style={{ fontSize: 150, color: '#100E71' }} spin />
        }
      />
    </Flex>
  );
}
export default Spinner;
