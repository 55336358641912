import { useQuery } from 'react-query';
import CategoriesLevelOne from './categories-level-one';

export default function useCategoriesLevelOne(page: number): {
  isLoading: boolean;
  data: any;
  error: unknown;
} {
  const { data, isLoading, error } = useQuery(['productCategory', page], () =>
    CategoriesLevelOne(page)
  );
  return {
    isLoading,
    data,
    error,
  };
}
