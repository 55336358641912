import { Typography, Form } from 'antd';
import React from 'react';
import IndividualForm from '../individual-form';

type InitialForm = {
  setIsNext: React.Dispatch<React.SetStateAction<string>>;
};
export default function InitalForm({ setIsNext }: InitialForm) {
  const [form] = Form.useForm();

  return (
    <div style={{ width: '100%' }}>
      <Typography.Title level={3}>
        Зарегистрируйтесь чтобы продолжить
      </Typography.Title>
      {/* <LegalEntityForm setIsNext={setIsNext} /> */}
      <IndividualForm setIsNext={setIsNext} form={form} />
    </div>
  );
}
