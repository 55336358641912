import React from 'react';
import { useNavigate } from 'react-router-dom';

function UserAgreement() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        fontFamily: 'Roboto, sans-serif',
        fontSize: '18px',
        lineHeight: '1.6',
        margin: '0',
        padding: '20px',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: 'auto',
          backgroundColor: '#f9f9f9',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>
          СОГЛАШЕНИЕ
          <br />
          (на сбор, обработку и хранение персональных данных)
        </h1>
        <p>
          Настоящим соглашением, соглашаясь с условиями договора пользования
          веб-сайтом{' '}
          <a href="https://amantrans.com.kz/">https://amantrans.com.kz/</a>{' '}
          (далее - Сайт), используя сервис и обращаясь к услугам «АТ Logistics»
          (далее - Компания), Пользователь дает свое согласие на сбор, обработку
          и хранение персональных данных, для осуществления Компанией всех
          таможенных процедур, а также иных действий, указанных в договоре
          пользования веб-сайтом.
        </p>

        <h2>1. Политика конфиденциальности</h2>
        <p>
          1.1. Настоящая Политика конфиденциальности (далее - Политика)
          распространяется на всех посетителей сайта и пользователей услуг
          Компании (далее-Пользователь).
        </p>
        <p>
          1.2. Настоящая Политика регулирует способы сбора, хранения, обработки
          и использования информации, полученной во время пользования сайтом
          (далее-Сайт)
        </p>
        <p>
          1.3. Политика распространяется на веб-сайты, товары, услуги и любые
          другие версии программного обеспечения Компании.
        </p>
        <p>
          1.4. Политика устанавливает права и обязанности Пользователя Сайта.
          Если Пользователь не согласен с условиями, установленными политикой
          конфиденциальности, Пользователь не может пользоваться услугами,
          предоставляемыми Сайтом.
        </p>
        <p>
          1.5. Компания имеет право вносить изменения в настоящую Политику
          Конфиденциальности.
        </p>
        <p>
          1.6. Компания имеет право без согласия Пользователей использовать
          персональные данные и информации, полученной во время пользования
          сайтом для осуществления других своих продуктов.
        </p>
        <p>
          1.7. Пользователь может посещать сайт без регистрации, но, чтобы
          воспользоваться услугами Компании, необходимо зарегистрироваться.
        </p>
        <p>
          1.8. Персональные данные начинают обрабатываться лишь с момента их
          предоставления.
        </p>
        <p>
          1.9. При регистрации Пользователь обязан указать свое имя, адрес,
          адрес электронной почты и контактные данные.
        </p>
        <p>
          1.10. Компания также может собирать информацию другими способами
          вовремя пользования сайтом. Сервера Компании получают и собирают такие
          данные Пользователя, как IP-адрес, название браузера, тип компьютера,
          технические данные о Пользователе и средства связи с сайтом Компании,
          вид операционной системы, имя провайдера Интернет-услуг и другие
          подобные сведения.
        </p>
        <p>
          1.11. Компания получает информацию о действиях Пользователя на Сайте,
          сведения из поступивших на Пользователя жалоб от других посетителей
          или из звонков Пользователя в центр поддержки клиентов Компании.
        </p>
        <p>
          1.12. Эти данные помогают Компании понять линию поведения Посетителей
          и их предпочтения, делать сайт более комфортным для использования и
          более точно предлагать услуги пользователям.
        </p>

        <h2>2. Конфиденциальность сведений о лицах, не достигших 18 лет</h2>
        <p>
          2.1. Компания не обрабатывает данные, не допускает регистрации и не
          предоставляет услуги лицам, не достигшим 18 лет.
        </p>
        <p>
          2.2. Компания не имеет возможности проверять достоверность вводимых
          данных, поэтому Компания не несет ответственности за последствия
          использования сайта и услуг лицами, не достигшими 18 лет.
        </p>

        <h2>3. Использование полученной информации</h2>
        <p>
          3.1. Компания вправе использовать предоставленные Пользователем
          сведения с целью:
        </p>
        <ul>
          <li>
            3.1.1. Удовлетворения запросов и реализации услуг Пользователя и
            осуществления других своих продуктов.
          </li>
          <li>
            3.1.2. Рекомендации услуг и товаров, которые могут быть интересны
            Пользователю.
          </li>
          <li>3.1.3. Оказания помощи в использовании Сайта Пользователю.</li>
          <li>3.1.4. Регулирования счета Пользователя.</li>
          <li>
            3.1.5. Внесения изменений в структуру Сайта для более комфортного
            его использования Пользователем.
          </li>
          <li>3.1.6. Защиты Сайта и других пользователей.</li>
        </ul>

        <h2>
          4. Способы хранения персональных данных пользователей и их
          безопасность
        </h2>
        <p>
          4.1. Компания применяет соответствующие технические и физические меры
          для защиты персональных данных от незаконного использования, изменения
          или разглашения.
        </p>
        <p>
          4.2. Все предоставленные Пользователем сведения хранятся на безопасных
          серверах.
        </p>
        <p>
          4.3. Каждую страницу, на которой хранятся персональные данные
          Пользователя, Компания защищает паролями.
        </p>
        <p>
          4.4. Компания никогда не попросит Пользователя сообщить пароль по
          телефону или электронной почте.
        </p>
        <p>
          4.5. Компания не предоставляет персональные данные третьим лицам без
          согласия Пользователя за исключением случаев, предусмотренных
          настоящим Соглашением и законодательством.
        </p>
        <p>
          4.6. Данные могут быть предоставлены связанным с Компанией лицам, если
          они необходимы для оказания или улучшения качества услуг.
        </p>
        <p>
          4.7. Для осуществления некоторых сделок от имени Пользователя таких,
          как группировка и складирование заказов, доставка почтовых
          отправлений, отправление писем, анализ базы данных, удаление
          повторяющейся информации, осуществление расчетов банковскими картами,
          клиринг, Компания нанимает другие компании и/или частные лица.
        </p>
        <p>
          4.8. Третьи лица получают доступ к необходимой для осуществления
          оговоренной деятельности информации и не имеют права использовать эти
          данные в других целях.
        </p>

        <h2>5. Использование Компанией файлов cookie</h2>
        <p>
          5.1. Компания имеет право использовать файлы cookie с целью сделать
          работу с Сайтом значительно более удобной для пользователя.
        </p>
        <p>
          5.2. Файлы cookie - это небольшие текстовые файлы, в которых хранятся
          сведения об активности пользователя. Существует два типа cookie файлов
          – сессионные или временные и постоянные. Сессионные cookie хранятся во
          временной памяти и удаляются, как только пользователь закрывает окно
          браузера. Постоянные cookie хранятся на жестком диске компьютера и
          передаются на сервер каждый раз, как пользователь посещает Сайт. В
          настройках браузера пользователи могут по своему усмотрению
          регулировать использование и сохранение cookie файлов. Многие браузеры
          имеют функцию отключения cookie или удаления файлов при закрытии
          браузера. Однако использование cookie при повторном посещении Сайта
          помогает Компании эффективнее выстроить работу с пользователем. С
          помощью файлов cookie Компания в автоматическом режиме сохраняет
          информацию об активности Пользователя на Сайте, переход по ссылкам,
          обзор страницы, поиск информации на Сайте и др.
        </p>

        <h2>6. Разрешение конфликтов</h2>
        <p>
          6.1. Зайдя на Сайт или воспользовавшись услугами Компании,
          Пользователь по умолчанию принимает условие, что все вопросы,
          связанные с использованием Сайта, регулируются законодательством
          Республики Казахстан.
        </p>
        <p>
          6.2. В случае возникновения спорных моментов, они будут урегулированы
          путем переговоров между сторонами конфликта. Если соглашение не будет
          достигнуто, вопрос подлежит рассмотрению в порядке, установленном
          законодательством.
        </p>

        {/* Кнопка "Назад" */}
        <button
          type="button"
          onClick={() => navigate(-1)}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            cursor: 'pointer',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
          }}
        >
          Назад
        </button>
      </div>
    </div>
  );
}

export default UserAgreement;
