import { useQuery } from 'react-query';
import SubCategory, { OneCategory } from './sub-category';

interface CatalogParams {
  id: number;
  page: number;
  size?: number;
}

export default function useSubCategory({ id, page, size }: CatalogParams) {
  const { data, refetch, isLoading } = useQuery(
    ['UseSubCategory', id, page, size],
    () => SubCategory({ id, page, size })
  );

  return { data, refetch, isLoading };
}
export function useOneCategory(id: number | undefined) {
  const { data, isLoading, isError } = useQuery(['UseOneCategory', id], () =>
    OneCategory(id)
  );
  return { data, isLoading, isError };
}
