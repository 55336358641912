import { Button, Form, Input, message, Select } from 'antd';
import { useState } from 'react';
import InputMask from 'react-input-mask';
import { useEditProfile } from '../../../api/my-profile/my-profile-hooks';

interface IUserInfo {
  fullName: string;
  phone: string;
  email: string;
}

interface EditProfileProps {
  userInfo: IUserInfo;
  setContentType: React.Dispatch<React.SetStateAction<string>>;
}

export default function EditProfile({
  userInfo,
  setContentType,
}: EditProfileProps) {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const { mutate } = useEditProfile();

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    form.submit();
    setIsEditable(false);
  };

  const handleFinish = (values: any) => {
    const { firstName, lastName, phone, email } = values;
    mutate(
      { fullName: firstName, lastName, phone, email },
      {
        onSuccess: () => {
          setContentType('history');
        },
        onError: (error: any) => {
          message.error(
            `Ошибка входа: ${error.response?.data?.message || error.message}`
          );
        },
      }
    );
  };

  return (
    <div
      className="edit-profile-block"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
      }}
    >
      {!isEditable && (
        <button
          type="button"
          onClick={handleEditClick}
          className="edit-profile-button"
        >
          Изменить профиль
        </button>
      )}
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        style={{ width: '100%' }}
      >
        {isEditable && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              type="primary"
              onClick={handleSaveClick}
              className="edit-profile-button"
            >
              Сохранить
            </Button>
          </div>
        )}

        <Form.Item
          label="Фамилия"
          name="fullName"
          className="edit-profile-form-title"
        >
          <Input
            placeholder="Введите ваше ФИО"
            disabled={!isEditable}
            defaultValue={userInfo.fullName}
            className="edit-profile-form-input"
          />
        </Form.Item>
        <Form.Item
          label="Имя"
          name="fullName"
          className="edit-profile-form-title"
        >
          <Input
            placeholder="Введите ваше ФИО"
            disabled={!isEditable}
            defaultValue={userInfo.fullName}
            className="edit-profile-form-input"
          />
        </Form.Item>
        <Form.Item
          label="Почта"
          name="email"
          className="edit-profile-form-title"
        >
          <Input
            placeholder="Введите почту"
            disabled={!isEditable}
            defaultValue={userInfo.email}
            className="edit-profile-form-input"
          />
        </Form.Item>
        {/* <Form.Item */}
        {/*  label="Город" */}
        {/*  name="city" */}
        {/*  className="edit-profile-form-title" */}
        {/* > */}
        {/*  <Select */}
        {/*    placeholder="Выберите ваш город" */}
        {/*    disabled={!isEditable} */}
        {/*    className="edit-profile-form-input" */}
        {/*  /> */}
        {/* </Form.Item> */}
        {/* <Form.Item */}
        {/*  label="Филиал" */}
        {/*  name="branch" */}
        {/*  className="edit-profile-form-title" */}
        {/* > */}
        {/*  <Select */}
        {/*    placeholder="Выберите филиал" */}
        {/*    disabled={!isEditable} */}
        {/*    className="edit-profile-form-input" */}
        {/*  /> */}
        {/* </Form.Item> */}
        <Form.Item
          label="Номер телефона"
          name="phone"
          className="edit-profile-form-title"
        >
          <InputMask
            mask="+7 (999) 999-99-99"
            placeholder="Введите ваш номер"
            disabled={!isEditable}
            defaultValue={userInfo.phone}
            className="edit-profile-form-input"
          />
        </Form.Item>
      </Form>
    </div>
  );
}
