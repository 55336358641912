import React from 'react';
import { Card, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import './category-card-main.scss';
import { Category } from '../../types/categoties';

interface CategoryCardProps {
  category: Category;
  backgroundColor?: string;
}

export default function CategoryCardMain({
  backgroundColor = '#F6F6F5',
  category,
}: CategoryCardProps) {
  const navigate = useNavigate();
  return (
    <Card
      className="category-main__card"
      onClick={() => navigate(`/category/subcategory/products/${category.id}`)}
      style={{
        backgroundColor: window.innerWidth <= 768 ? backgroundColor : 'white',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: 600,
      }}
    >
      <div className="category-main__card-cover">
        <h4 className="category-main__card__title">{category.categoryName}</h4>
        <Image
          // src={category.imageUrl || 'placeholder.jpg'}
          src={category?.imageUrl}
          alt={category.categoryName}
          className="category-main__image"
          preview={false}
        />
      </div>
    </Card>
  );
}
CategoryCardMain.defaultProps = {
  backgroundColor: '#F6F6F5',
};
