import { Button, Form, Input, theme, Typography } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { UserInfoContext } from '../index';
import useCreateUser from '../../../api/auth/auth-hooks';

type PasswordFormProps = {
  setIsNext: React.Dispatch<React.SetStateAction<string>>;
};

export default function PasswordForm({ setIsNext }: PasswordFormProps) {
  const [password, setPassword] = useState<string>('');
  const { token } = theme.useToken();
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const context = useContext(UserInfoContext);
  const { mutate } = useCreateUser();
  const [form] = Form.useForm();
  const check = () => {
    if (password !== passwordConfirm) {
      form.setFields([
        {
          name: 'passwordConfirm',
          value: passwordConfirm,
          errors: ['Passwords do not match'],
        },
      ]);
      return false;
    }
    return true;
  };

  const onNextStep = useCallback(() => {
    if (check()) {
      context.setUser({
        fullName: context.user.fullName,
        password: form.getFieldValue('password'),
        phoneNumber: context.user.phoneNumber,
        smsCode: '',
        username: '',
      });
      setIsNext('city');
    }
  }, [context, form, mutate, password, passwordConfirm]);

  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const onChangePasswordConfirm = (e: any) => {
    setPasswordConfirm(e.target.value);
  };

  const onBack = () => {
    setIsNext('initial');
  };
  return (
    <div style={{ width: '100%', textAlign: 'left' }}>
      <div style={{ textAlign: 'center' }}>
        <Typography.Title level={4} style={{ width: '100%' }}>
          Придумайте пароль
        </Typography.Title>
      </div>
      <Form style={{ width: '100%' }} layout="vertical" form={form}>
        <Form.Item
          name="password"
          style={{ margin: '0' }}
          label={<span style={{ color: '#6D7885' }}>Пароль</span>}
          rules={[{ required: true, message: 'Введите пароль!' }]}
        >
          <Input.Password
            className="register-form__input"
            style={{ width: '100', margin: '0' }}
            placeholder="Введите пароль"
            onChange={(e) => onChangePassword(e)}
          />
        </Form.Item>
        <span style={{ color: '#6D7885' }}>
          Пароль должен содержать не менее 8-ми символов, в том числе цифры, и
          заглавные буквы.
        </span>

        <Form.Item
          name="passwordFirm"
          label={<span style={{ color: '#6D7885' }}>Пароль</span>}
          style={{ marginTop: '20px' }}
        >
          <Input.Password
            className="register-form__input"
            placeholder="Введите пароль"
            style={{ margin: '0' }}
            onChange={(e) => onChangePasswordConfirm(e)}
          />
        </Form.Item>

        <Button
          onClick={onNextStep}
          htmlType="submit"
          className="register-form__submit-btn"
          style={{
            backgroundColor: token.colorPrimaryBg,
            border: token.colorPrimaryBg,
          }}
        >
          Продолжить
        </Button>

        <Button
          onClick={onBack}
          className="register-form__back-btn"
          style={{
            borderColor: token.colorPrimaryBg,
            color: token.colorPrimaryBg,
          }}
        >
          Назад
        </Button>
      </Form>
    </div>
  );
}
