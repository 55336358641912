import { QueryObserverResult, useQuery } from 'react-query';
import getHomePageProducts, {
  getHomePageCategories,
} from './homepage-products';
import { PopularProduct } from '../../types/popular-products';

interface HomePageProductsResponse {
  content: PopularProduct[];
}
export default function useHomePageProducts(): {
  data: HomePageProductsResponse | undefined;
  refetch: () => Promise<QueryObserverResult<HomePageProductsResponse, Error>>;
} {
  const { data, refetch } = useQuery<HomePageProductsResponse, Error>(
    ['homePageProducts'],
    getHomePageProducts
  );

  return { data, refetch };
}
export function useHomePageCategories() {
  const { data, refetch } = useQuery(
    ['useHomePageCategories'],
    getHomePageCategories
  );

  return { data, refetch };
}
