import { Card, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import allCategoryes from '../../icons/svg/Все категории.png';
import './category-card-main.scss';

type CategoryCardProps = {
  name: string;
  backgroundColor?: string;
};

export default function AllCategory({
  backgroundColor = '#F6F6F5',
  name,
}: CategoryCardProps) {
  const navigate = useNavigate();
  // const titleStyle = {
  //   margin: '0',
  //   fontSize: window.innerWidth <= 768 ? '7.678px' : '24px',
  // };

  return (
    <motion.div>
      <Card
        className="category-main__card"
        onClick={() => navigate('/categories')}
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            window.innerWidth <= 768 ? `${backgroundColor}` : 'white',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: '600',
        }}
      >
        <div className="category-main__card-cover">
          <h4
            // style={titleStyle}
            className="category-main__card__title"
          >
            {name || 'No Category Name'}
          </h4>
          <Image
            src={allCategoryes}
            alt="Category"
            className="category-main__image"
            preview={false}
          />
        </div>
      </Card>
    </motion.div>
  );
}
AllCategory.defaultProps = {
  backgroundColor: '#F6F6F5',
};
