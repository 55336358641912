import { api } from '../api';
import { IViewedProducts } from '../../types/viewed-products';

interface HomePageProductsResponse {
  content: IViewedProducts[];
}
export default async function getViewedProducts(): Promise<HomePageProductsResponse> {
  const token = localStorage.getItem('ACCESS_TOKEN');

  const { data } = await api.get(
    `internal/recently-viewed-products?page=0&size=1`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}
