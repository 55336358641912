import { api } from '../api';

export default async function addToFavorite(itemId: number) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  const { data } = await api.post(
    `/internal/favorites/add?productId=${itemId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}
export async function deleteFromFavorite(itemId: number) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  const { data } = await api.post(
    `/internal/favorites/delete?productId=${itemId}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}

// Создайте файл updateBasketItem.ts для обработки PUT-запроса:
//
// ts
// Копировать код
// import { api } from "../api";
//
// export default async function updateBasketItem(itemId: number, updatedData: any) {
// 	const { data } = await api.put(`cart/update/${itemId}`, updatedData);
//
// 	return data;
// }
// 2. POST-запрос (создание нового элемента)
// Создайте файл addBasketItem.ts для обработки POST-запроса:
//
// ts
// Копировать код
// import { api } from "../api";
//
// export default async function addBasketItem(newItemData: any) {
// 	const { data } = await api.post(`cart/add`, newItemData);
//
// 	return data;
// }
// 3. DELETE-запрос (удаление элемента)
// Создайте файл deleteBasketItem.ts для обработки DELETE-запроса:
//
// ts
// Копировать код
// import { api } from "../api";
//
// export default async function deleteBasketItem(itemId: number) {
// 	const { data } = await api.delete(`cart/remove/${itemId}`);
//
// 	return data;
// }
// Вызов запросов в компонентах
// 1. GET-запрос (получение данных корзины)
// Для получения элементов корзины, как вы уже показали:
//
// 	ts
// Копировать код
// import useBasketItems from "./basket";
//
// export default function BasketComponent() {
// 	const { data: basketItems } = useBasketItems();
//
// 	return (
// 		<div>
// 			{basketItems?.map((item: any) => (
// 				<div key={item.id}>
// 					<p>{item.name}</p>
// 					<p>{item.price}</p>
// 					</div>
// 			))}
// 	</div>
// );
// }
// 2. POST-запрос (добавление нового элемента в корзину)
// Для добавления элемента в корзину, используйте useMutation из react-query:
//
// ts
// Копировать код
// import { useMutation } from "react-query";
// import addBasketItem from "./addBasketItem";
//
// export default function AddToBasketComponent() {
// 	const mutation = useMutation(addBasketItem);
//
// 	const handleAddToBasket = () => {
// 		const newItem = {
// 			name: "New Item",
// 			price: 100,
// 		};
// 		mutation.mutate(newItem);
// 	};
//
// 	return (
// 		<div>
// 			<button onClick={handleAddToBasket}>Добавить в корзину</button>
// 	{mutation.isLoading && <p>Добавление...</p>}
// 		{mutation.isSuccess && <p>Товар добавлен!</p>}
// 		</div>
// 		);
// 		}
// 		3. PUT-запрос (обновление элемента корзины)
// 		Для обновления элемента корзины:
//
// 		ts
// 		Копировать код
// 		import { useMutation } from "react-query";
// 		import updateBasketItem from "./updateBasketItem";
//
// 		export default function UpdateBasketComponent({ itemId }: { itemId: number }) {
// 			const mutation = useMutation((updatedData) => updateBasketItem(itemId, updatedData));
//
// 			const handleUpdate = () => {
// 				const updatedData = {
// 					name: "Updated Item",
// 					price: 150,
// 				};
// 				mutation.mutate(updatedData);
// 			};
//
// 			return (
// 				<div>
// 					<button onClick={handleUpdate}>Обновить товар</button>
// 			{mutation.isLoading && <p>Обновление...</p>}
// 				{mutation.isSuccess && <p>Товар обновлен!</p>}
// 				</div>
// 				);
// 				}
// 				4. DELETE-запрос (удаление элемента из корзины)
// 				Для удаления элемента корзины:
//
// 				ts
// 				Копировать код
// 				import { useMutation } from "react-query";
// 				import deleteBasketItem from "./deleteBasketItem";
//
// 				export default function DeleteBasketComponent({ itemId }: { itemId: number }) {
// 					const mutation = useMutation(() => deleteBasketItem(itemId));
//
// 					const handleDelete = () => {
// 						mutation.mutate();
// 					};
//
// 					return (
// 						<div>
// 							<button onClick={handleDelete}>Удалить товар</button>
// 					{mutation.isLoading && <p>Удаление...</p>}
// 						{mutation.isSuccess && <p>Товар удален!</p>}
// 						</div>
// 						);
// 						}
