// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ns1 from './locales/ru/translation.json';
import ns2 from './locales/kz/translation.json';

export const defaultNS = 'ns1'; // Дефолтное пространство имен
export const resources = {
  ru: {
    ns1, // добавь другие языки и их переводы
  },
  kz: {
    ns2, // добавь другие языки и их переводы
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'ru', // Устанавливаем язык по умолчанию
  ns: ['ns1', 'ns2'], // Указываем все пространства имен
  defaultNS,
  resources,
});

export default i18n;
