import { api } from '../api';

export default async function addBasket(id: number) {
  const token = localStorage.getItem('ACCESS_TOKEN');
  if (!token) {
    throw new Error('Токен не найден. Пожалуйста, авторизуйтесь.');
  }
  await api.post(`/internal/cart/add?productId=${id}&amount=1`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
