// import React, { useEffect, useState } from "react";
// import { Button, Carousel, Tag, Typography } from "antd";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
//
// import "./styles.css";
// import ProductMainInfo from "./product-main-info";
// import ProductCharacteristicsInfo from "./product-characteristics-info";
// import ProductReviews from "./product-reviews";
// import useProductDetail from "../../api/products/products-hooks";
// import { getProductId } from "../products-id/products-id";
//
// export default function ProductDetailPage() {
//   const [product, setProduct] = useState(null);
//   const [error, setError] = useState<Error | string | null>(null);
//
//   useEffect(() => {
//     const token = localStorage.getItem("ACCESS_TOKEN");
//     console.log(token);
//     if (token) {
//       getProductId(2, token)
//         .then((data) => {
//           setProduct(data);
//         })
//         .catch((error) => {
//           setError(error);
//         });
//     } else {
//       setError("No token found");
//     }
//   }, []);
//   console.log(product, 999999999999999);
//   if (error && typeof error === "object" && "message" in error) {
//     return <div>Error: {error.message}</div>;
//   }
//
//   if (!product) {
//     return <div>Loading...</div>;
//   }
//
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         backgroundColor: "white",
//         paddingTop: "20px",
//         paddingLeft: "5%",
//         paddingBottom: "10%",
//       }}
//     >
//       <div style={{ maxWidth: "1440px" }}>
//         <div>
//           <div
//             style={{
//               display: "flex",
//               gap: "5px",
//             }}
//           >
//             <Tag className="product-page__history-tage">Товары</Tag>
//             <Tag className="product-page__history-tage">
//               {product?.subcategory_name}
//             </Tag>
//             <Tag className="product-page__history-tage">
//               {product?.category_name}
//             </Tag>
//             <Tag className="product-page__history-tage">Мужские кроссовки</Tag>
//           </div>
//
//           <ProductMainInfo data={product} />
//           <div />
//         </div>
//         <div id="product-description">
//           <Typography.Title level={2}>Описание товара</Typography.Title>
//           <div style={{ fontSize: "20px" }}>{product?.description}</div>
//           <ProductCharacteristicsInfo />
//         </div>
//
//         <ProductReviews data={product?.reviewsList} />
//         <div style={{ marginTop: "50px" }}>
//           <Typography.Title level={1}>Похожие товары</Typography.Title>
//           <div style={{ padding: "20px" }}>
//             <Carousel
//               arrows
//               autoplay
//               autoplaySpeed={4000}
//               dots={false}
//               slidesToShow={4}
//               nextArrow={
//                 <Button style={{ padding: "!20px" }}>
//                   <RightOutlined
//                     style={{
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   />
//                 </Button>
//               }
//               prevArrow={
//                 <Button style={{ padding: "20px" }}>
//                   <LeftOutlined
//                     style={{
//                       color: "black",
//                       fontSize: "20px",
//                     }}
//                   />
//                 </Button>
//               }
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Carousel, Tag } from 'antd';

import './styles.css';

import { useParams, useNavigate } from 'react-router-dom';
import {
  LeftOutlined,
  RightOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import ProductMainInfo from './product-main-info';
import ProductCharacteristicsInfo from './product-characteristics-info';
import { useProductById } from '../../api/products/products-hooks';
import useHomePageProducts from '../../api/popular-products/homepage-products-hooks';
import PopularProductCard from '../../components/popular-product-card';

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
export default function ProductDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { data: Product, refetch: RefetchProductById } = useProductById(id);
  const { data: HomePageProducts, refetch: RefetchProducts } =
    useHomePageProducts();
  const popularProducts = HomePageProducts?.content || [];

  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 800);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const navigate = useNavigate();
  // getProductReviewsById(3)
  //   .then((data: ProductReviewsById) => {
  //     setProductReviews(data);
  //     console.log(data);
  //   })
  //   .catch((err: Error) => {
  //     setError(err);
  //   });
  // }, []);

  // if (error && typeof error === 'object' && 'message' in error) {
  //   return <div>Error: {error.message}</div>;
  // }
  //
  // if (!product) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="product-main-div">
      <div className="container">
        <div className="product-info">
          <div className="product-category">
            {/* <Tag className="product-page__history-tage">
              {Product?.categoryName}
            </Tag>
            <Tag className="product-page__history-tage">
              {Product?.subcategoryName}
            </Tag> */}
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)} // Возврат на предыдущую страницу
              className="product-main__back-button"
            >
              Назад
            </Button>
          </div>
          <ProductMainInfo data={Product} refetch={RefetchProductById} />
        </div>

        <div className="product-description">
          <div className="product__info-title">Описание товара</div>
          <div className="product-full-description">{Product?.description}</div>
        </div>
        {/* <ProductCharacteristicsInfo /> */}
        {/* <ProductReviews data={productReviews} /> */}
        {/* <Typography.Title level={1}>С этим товаром покупают</Typography.Title> */}
        <Carousel
          arrows={!isMobile}
          draggable={isMobile}
          slidesToShow={isMobile ? 3 : 4}
          autoplay
          autoplaySpeed={4000}
          dots={false}
          className="main-carousel-two"
          nextArrow={
            <Button style={{ padding: '20px' }}>
              <RightOutlined
                style={{
                  color: 'black',
                  fontSize: '20px',
                }}
              />
            </Button>
          }
          prevArrow={
            <Button style={{ padding: '20px' }}>
              <LeftOutlined
                style={{
                  color: 'black',
                  fontSize: '20px',
                }}
              />
            </Button>
          }
        >
          {popularProducts.map((product) => (
            <motion.li
              className="popular-cards"
              variants={item}
              key={product.id}
            >
              <PopularProductCard refetch={RefetchProducts} product={product} />
            </motion.li>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
