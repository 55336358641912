import { api } from '../api';
import { PopularProduct } from '../../types/popular-products';

interface HomePageProductsResponse {
  content: PopularProduct[];
}
export default async function getHomePageProducts(): Promise<HomePageProductsResponse> {
  const token = localStorage.getItem('ACCESS_TOKEN');

  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  const { data } = await api.get(`/homePage/popular-products`, {
    headers,
    params: {
      page: 0,
      size: 12,
    },
  });

  return data;
}

export async function getHomePageCategories() {
  const { data } = await api.get(
    `/homePage/categories?page=0&size=1&sort=string`
  );

  return data;
}
