import React from 'react';
import NonEmptyFavouriteProducts from './non-empty-favourite';

export default function FavouriteProductsPage() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '20%',
      }}
    >
      <div style={{ maxWidth: '1440px', minWidth: '1320px' }}>
        <NonEmptyFavouriteProducts />
      </div>
    </div>
  );
}
