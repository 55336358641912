import { Button, Typography, theme } from 'antd';

import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import CartSvg from '../../../icons/Cart';

export default function EmptyCart() {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ height: isMobile ? '100%' : '74vh' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '60%',
          }}
        >
          <CartSvg />
          <Typography.Title level={3}>Корзина пуста</Typography.Title>
          <Typography.Title
            level={4}
            style={
              isMobile
                ? {
                    color: '#898D94',

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    fontSize: '14px',
                    margin: '0',
                  }
                : {
                    color: '#898D94',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '555px',
                  }
            }
          >
            <div style={{ textAlign: 'center' }}>
              Воспользуйтесь каталогом или поиском для выбора товаров.
            </div>
            <div style={{ textAlign: 'center' }}>
              Если в корзине были товары, войдите, чтобы посмотреть список.
            </div>
          </Typography.Title>
          <Button
            style={{
              background: token.colorPrimaryBg,
              color: 'white',
              padding: '20px',
              lineHeight: '0px',
              fontSize: '20px',
              marginTop: '10px',
              borderRadius: '30px',
            }}
            onClick={() => navigate('/')}
          >
            На главную
          </Button>
        </div>
      </div>
    </div>
  );
}
